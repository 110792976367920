import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const SkilledMigration = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Skilled Migration"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                The skilled migration is designed to attract migrants who make a
                significant contribution to the Australian economy, and fill
                positions where no Australian workers are available. The skilled
                migration also plays an important role in regional development
                through providing skills and labour which can’t be sourced
                locally, as well as encouraging investment and promoting local
                spending in regional areas. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="SKILLED MIGRATION"
                  title="Skilled Independent visa (Subclass 189)"
                />
                <p>
                  This visa lets invited workers with skills we need live and
                  work permanently anywhere in Australia. You do not need a
                  sponsor or nominator but must be invited to apply.
                </p>
                <h6>You must:</h6>
                <ul>
                  <li>Be aged under 45</li>
                  <li>
                    Be invited to apply through SkillSelect – Expression of
                    Interest (EOI)
                  </li>
                  <li>
                    Have an occupation on the relevant skilled occupation list
                  </li>
                  <li>Have a suitable skills assessment for the occupation</li>
                  <li>
                    Have this level of English language, which is at least
                    competent English{" "}
                  </li>
                  <li>Meet health requirement </li>
                  <li>Meet character requirement </li>
                  <li>Satisfy the 65 points test: 189 Points Table</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Skilled Nominated visa (Subclass 190)" />
              </div>
              <p>
                This is a permanent visa which requires nomination by a State or
                Territory Government.
              </p>
              <h6>You must</h6>
              <ul>
                <li>Be aged under 45</li>
                <li>
                  Be invited to apply through SkillSelect – Expression of
                  Interest (EOI)
                </li>
                <li>Be nominated by a State or Territory Government</li>
                <li>
                  Have an occupation on the relevant skilled occupation list and
                  State/Territory nominated occupation list
                </li>
                <li>Have a suitable skills assessment for the occupation</li>
                <li>
                  Have this level of English language, which is at least
                  competent English{" "}
                </li>
                <li>Meet health requirement </li>
                <li>Meet character requirement </li>
                <li>Satisfy the 65 points test: 190 Points Table</li>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="faq-section section bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Skilled Work Regional (Provisional) visa (Subclass 491)" />
              </div>
              <p>
                This is a provisional visa for 5 years. It is for skilled
                workers who want to live and work in designated regional areas
                of Australia.
              </p>
              <h6>You must</h6>
              <ul>
                <li>Be under 45 years of age</li>
                <li>
                  Be invited to apply through SkillSelect – Expression of
                  Interest (EOI)
                </li>
                <li>Be nominated by a State or Territory Government</li>
                <li>
                  Have an occupation on the relevant skilled occupation list and
                  State/Territory nominated occupation list
                </li>
                <li>Have a suitable skills assessment for the occupation</li>
                <li>
                  Have this level of English language, which is at least
                  competent English{" "}
                </li>
                <li>Meet health requirement </li>
                <li>Meet character requirement </li>
                <li>Satisfy the 65 points test from 491 Points Table</li>
              </ul>
              <h6>Designated regional area</h6>
              <p>
                You and all members of your family unit who are also holders of
                the visa, will need to live, work and study in a designated
                regional area of Australia. Your nomination must not be
                withdrawn, or you will not be granted the visa.
              </p>
              <h6>Migration Pathway </h6>
              <p>
                Visa holders can apply for permanent residence, if eligible,
                through the Permanent Residence (Skilled Regional) visa
                (Subclass 191), which commences on 16 November 2022.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default SkilledMigration;
