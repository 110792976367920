import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import IconBox from "../components/IconBox/IconBox2.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import HomeAboutTwo from "../components/About/HomeAboutNine.jsx";

const StudyTour = () => {
  const IconBoxData = [
    {
      id: "01.",
      title: "Consulting",
      desc: "Consulting your future career and what qualifications from which institutions are needed to achieve it",
    },
    {
      id: "02.",
      title: "Documentation",
      desc: "Preparing the documents needed for your application",
    },
    {
      id: "03.",
      title: "Apply School",
      desc: "Applying to your desired educational institutions such as university, TAFE, or english course",
    },
    {
      id: "04.",
      title: "Offer letter",
      desc: "Having a conditional offer/offer letter issued from the institution and Fulfilling/accepting the offer",
    },
    {
      id: "05.",
      title: "Payment",
      desc: "Making a payment to secure your spot at the institution",
    },
    {
      id: "06.",
      title: "Issue eCOE",
      desc: "Having a confirmation of enrolment (COE) issued from the institution",
    },
    {
      id: "07.",
      title: "Apply Visa",
      desc: "Apply for visa after your COE is issued",
    },
    {
      id: "08.",
      title: "Visa Granted",
      desc: "Having your visa issued",
    },
    {
      id: "09.",
      title: "Departure",
      desc: "Preparation for departure",
    },
  ];
  return (
    <React.Fragment>
      <SEO title="Admission Requirements | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/education.jpg"
        title="Admission Requirements Education"
        content="Home"
        contentTwo="Our Services"
      />
      <div className={`section section-padding-t90-b100`}>
        <div className="container">
          <SectionTitle
            title="Admission Process"
            subTitle="For education purposes, some requirements such as academic results, financial status, english proficiency, and insurance coverage might be compulsory.
The admission process for international students are typically:"
          />

          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {IconBoxData &&
              IconBoxData.map((single, key) => {
                return (
                  <div key={key} className="col mb-6" data-aos="fade-up">
                    <IconBox classOption="box-border" data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <CallToAction />
      <HomeAboutTwo />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default StudyTour;
