import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
                />
                {/* <p>
                  As an experienced education and migration agency, Time Study
                  believes that every case brought to us, no matter how small it
                  may be, is important.
                </p> */}
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      (+82) 707 567 6787
                    </Link>
                  </p>
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      info@timest.com.au
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer-social-inline">
                  {/* <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter-square"></i>
                  </a> */}
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              Copyright &copy; 2021 <strong>Time Study Pty Ltd</strong> Powered
              By &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ebizlab.co/"
              >
                <strong>Ebizlab</strong>
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
