import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const VisitorVisa = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Visitor Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                The Visitor visa (Subclass 600) allows tourists, business
                visitors or to visit family for 3, 6 or 12 months. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="VISITOR VISA"
                  title="Visitor visa (subclass 600)"
                />
                <p>
                  Visitor visa allows tourists, business visitors or to visit
                  family for 3, 6 or 12 months.
                </p>
                <h4>Tourist stream (apply in Australia)</h4>
                <p>
                  This visa stream lets you visit Australia as a tourist, to see
                  family and friends or for purposes other than business or
                  medical treatment. after your marriage in Australia.
                </p>
                <h6>You must:</h6>
                <ul>
                  <li>intend to stay in Australia to visit only</li>
                  <li>have enough money for your stay in Australia</li>
                </ul>
                <h6>Stay</h6>
                <p>Up to 12 months </p>
                <h4>Tourist stream (apply outside Australia)</h4>
                <p>
                  This visa stream lets you visit Australia as a tourist, to go
                  on a cruise or to see family and friends.
                </p>
                <h6>You must</h6>
                <ul>
                  <li>intend to come to Australia to visit only</li>
                  <li>have enough money for your stay in Australia</li>
                  <li>not work in Australia</li>
                </ul>
                <h6>Stay</h6>
                <p>Up to 12 months </p>
                <h4>Sponsored family stream</h4>
                <p>
                  This visa stream lets people who are sponsored, usually by a
                  family member, come to Australia to visit their family
                  members.
                </p>
                <ul>
                  <li>
                    You must be sponsored by an eligible family member who is an
                    Australian citizen or permanent resident
                  </li>
                  <li>We might ask your sponsor to pay a security bond</li>
                  <li>
                    With this visa you can study or train for up to 3 months
                  </li>
                </ul>
                <h6>Stay</h6>
                <p>Up to 12 months </p>
                <h4>Business visitor stream</h4>
                <p>
                  This visa stream lets you visit Australia for business
                  reasons.
                </p>
                <h6>With this visa you can:</h6>
                <ul>
                  <li>
                    make a short visit for business visitor reasons, but you
                    can’t work or sell goods or services
                  </li>
                  <li>negotiate contracts </li>
                  <li>
                    take part in a conference or trade fair. You can’t be paid
                    by the organisers to take part
                  </li>
                </ul>
                <h6>You must: </h6>
                <ul>
                  <li>
                    be a genuine visitor travelling to Australia for business
                    visitor activities
                  </li>
                  <li>
                    be outside Australia when you apply and when we decide your
                    application
                  </li>
                </ul>
                <h6>Stay</h6>
                <p>Up to 12 months </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default VisitorVisa;
