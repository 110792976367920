import PropTypes from "prop-types";
import React from "react";

const IconBox = ({ data, classOption }) => {
  return (
    <div className={`icon-box text-center ${classOption}`}>
      <div className="icon" style={{ fontSize: "40px", color: "blue" }}>
        {data.id}
      </div>
      <div className="content">
        <h3 className="title">{data.title}</h3>
        <div className="desc">
          <p>{data.desc}</p>
        </div>
      </div>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

IconBox.defaultProps = {
  classOption: "icon-box text-center",
};

export default IconBox;
