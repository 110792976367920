import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const BusinessInvestment = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Business & Investment Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                This visa is for people with business skills who want to own and
                manage a new or existing business in Australia, or to invest in
                an Australian state or territory. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="BUSINESS & INVESTMENT"
                  title="Business Innovation and Investment (Provisional) visa (subclass 188)"
                />
                <p>
                  This visa allows you to own and manage a business in
                  Australia, conduct business and investment activity in
                  Australia or undertake an entrepreneurial activity in
                  Australia
                </p>
                <h4>Basic Eligibility</h4>
                <ul>
                  <li>Submit expression of interest (EOI) in SkillSelect</li>
                  <li>
                    Nominated by a State or Territory government agency or
                    Austrade{" "}
                  </li>
                  <li>Invited to apply for the visa</li>
                </ul>
                <h4>A. Business Innovation stream</h4>
                <p>
                  This provisional visa is for people with business skills. It
                  lets you operate a new or existing business in Australia.{" "}
                </p>
                <h6>With this visa, you can</h6>
                <ul>
                  <li>stay up to 4 years and 3 months </li>
                  <li>operate a new or existing business in Australia</li>
                  <li>bring eligible members of your family with you</li>
                  <li>
                    apply for a permanent Business Innovation and Investment
                    (Permanent) visa (subclass 888) Business Innovation stream
                  </li>
                </ul>
                <h6>You must</h6>
                <ul>
                  <li>Be under 55 </li>
                  <li>Score at least 65 on the points test </li>
                  <li>
                    Have business ownership for 2 out of 4 fiscal years
                    immediately before you receive an invitation to apply, you
                    must have had an ownership stake in 1 or 2 businesses that
                    had at least AUD500,000 turnover in each of those 2 years.
                    <ul>
                      <li>
                        51%, of the total value of the business if the business
                        has a turnover of less than AUD400,000 per year
                      </li>
                      <li>
                        30%, of the total value of the business if the business
                        has a turnover of AUD400,000 or more per year, or
                      </li>
                      <li>
                        10%, of the total value of the business if the business
                        is operated by a publicly listed company
                      </li>
                    </ul>
                  </li>
                  <li>
                    Provide proof of your business success (annual turnover,
                    ownership stake)
                  </li>
                  <li>
                    Own personal and business assets of at least AUD800,000 by
                    you and your partner combined with. Your funds must be
                    available to transfer to Australia within 2 years of us
                    granting the visa.
                  </li>
                  <li>
                    Be nominated by an Australian State or Territory government
                    agency, before being invited to apply for this visa.
                  </li>
                </ul>
                <h4>B. Investor stream </h4>
                <p>
                  This provisional visa requires you to invest at least AUD1.5
                  million in an Australian State or Territory and maintain
                  business or investment activity in Australia.
                </p>
                <h6>With this visa, you can</h6>
                <ul>
                  <li>stay up to 4 years and 3 months </li>
                  <li>
                    carry out business and investment activity in Australia
                  </li>
                  <li>bring eligible members of your family with you</li>
                  <li>
                    apply for a permanent Business Innovation and Investment
                    (Permanent) visa (subclass 888) Business Innovation stream
                  </li>
                </ul>
                <h6> You must</h6>
                <ul>
                  <li>Be under 55 </li>
                  <li>Score at least 65 on the points test </li>
                  <li>
                    Be nominated by an Australian State or Territory government
                    agency, before being invited to apply for this visa.
                  </li>
                  <li>
                    Have a genuine intention to live for at least 2 years in the
                    State or Territory in which you have made the investment
                    application.
                  </li>
                  <li>
                    Own and manage business and personal assets and investments
                    <ul>
                      <li>
                        Have managed a qualifying business or investments to the
                        value of at least AUD1.5million. For at least 1 of the 5
                        fiscal years immediately before we invite you to apply,
                        have directly managed one of the following:
                        <ul>
                          <li>
                            a qualifying business in which you, your partner or
                            you and your partner combined had at least 10%
                            ownership interest, or
                          </li>
                          <li>
                            an eligible investment of at least AUD1.5 million
                            owned by you, your partner or you and your partner
                            combined.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Have assets of AUD2.25 million. For the 2 fiscal years
                        immediately before we invite you to apply, you, your
                        partner, or you and your partner together, have business
                        and personal assets with a net value of at least AUD2.25
                        million. These assets must have been lawfully acquired
                        and be available for transfer to Australia within 2
                        years of the visa being granted.{" "}
                      </li>
                      <li>
                        Make an investment of AUD1.5 million in your nominating
                        State or Territory. After you lodge your application,
                        you must make a designated investment of at least AUD1.5
                        million in the State or Territory in which the
                        nominating State or Territory government agency is
                        located. This investment must be in your name or in your
                        name and your partner’s name. The investment must be
                        made from unencumbered funds generated from qualifying
                        businesses or eligible investments. To be eligible for
                        the Business Innovation and Investment (Permanent) visa
                        (subclass 888) Investor stream, you must hold the
                        designated investment for at least 4 years from the date
                        the investment certificate is issued by your nominating
                        State or Territory.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Have the right experience
                    <ul>
                      <li>
                        At least 3 years’ experience managing one or more
                        qualifying businesses or eligible investments
                      </li>
                      <li>
                        A successful record of eligible investment or qualifying
                        business activity
                      </li>
                      <li>
                        Demonstrated a high level of management skill in
                        relation to the eligible investment or qualifying
                        business activity
                      </li>
                    </ul>
                  </li>
                </ul>
                <h4>C. Significant Investor stream</h4>
                <p>
                  This provisional visa is for people who invest at least AUD5
                  million in Australian investments that meet certain
                  requirements and maintain investment activity in Australia.
                </p>
                <h4>With this visa, you can</h4>
                <ul>
                  <li>carry out investment activity in Australia</li>
                  <li>
                    apply for a permanent Business Innovation and Investment
                    (Permanent) visa (subclass 888) Significant Investor stream
                    if certain requirements are met.
                  </li>
                </ul>
                <h4>You must</h4>
                <ul>
                  <li>
                    Be nominated by an Australian State or Territory government
                    agency, before being invited to apply for this visa.
                  </li>
                  <li>
                    Be able to invest at least AUD5 million. To be granted this
                    visa, you must make (on or after the time of application for
                    this visa), a complying significant investment of at least
                    AUD5 million when we invite you to, and have a genuine
                    intention to hold that investment for at least 4 years. It
                    must be invested in the following proportions:
                    <ul>
                      <li>
                        At least AUD500,000 in venture capital and growth
                        private equity funds which invest in start-ups and small
                        private companies
                      </li>
                      <li>
                        At least AUD1.5 million in approved managed funds. The
                        managed funds must invest in emerging companies listed
                        on the Australian Stock Exchange
                      </li>
                      <li>
                        A ‘balancing investment’ of at least AUD3 million in
                        managed funds
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  The managed funds can invest in a range of assets, including
                  companies listed on the Australian stock exchange, Australian
                  corporate bonds or notes, annuities and commercial real
                  estate. We prohibit direct investment in residential real
                  estate. We strictly limit indirect investment in residential
                  property through managed funds. You must not use investments
                  as security or collateral for a loan.
                </p>
                <h4>D. Premium Investor stream </h4>
                <p>
                  This provisional visa is for people who are nominated by
                  Austrade and who invest at least AUD15 million in Australian
                  investments and/or philanthropic contributions.
                </p>
                <h6>You must</h6>
                <ul>
                  <li>
                    Be nominated by an Australian State or Territory government
                    agency, before being invited to apply for this visa.
                  </li>
                  <li>
                    Have net assets of at least AUD15 million. These assets
                    could belong to you, your partner, or you and your partner
                    combined. They must be legally acquired, unencumbered, and
                    available to make the complying premium investment in
                    Australia.
                  </li>
                  <li>
                    Make a complying premium investment of at least AUD15
                    million.
                  </li>
                </ul>
                <p>
                  After you lodge your application, you must make a complying
                  premium investment of at least AUD15 million in any of the
                  following options
                </p>
                <ul>
                  <li>Australian securities exchange listed assets</li>
                  <li>
                    Australian government or semi-government bonds or notes
                  </li>
                  <li>
                    corporate bonds or notes issued by an Australian exchange
                    listed entity (or wholly owned subsidiary of the Australian
                    listed entity), investment grade rated Australian corporate
                    bonds or notes rated by an AFS licenced debt rating agency
                  </li>
                  <li>
                    Australian proprietary limited companies that operate
                    qualifying businesses (providing goods or services to the
                    public with a profit-making intention, and not passive or
                    speculative in nature)
                  </li>
                  <li>
                    real property in Australia excluding residential property
                  </li>
                  <li>
                    deferred annuities issued by Australian registered life
                    companies
                  </li>
                  <li>
                    state or territory government approved philanthropic
                    contribution{" "}
                  </li>
                </ul>
                <p>
                  To be an eligible fund, a further requirement is that cash is
                  to be no more than 20% of a fund’s net assets and derivatives
                  are to be used for risk management purposes only. Premium
                  investors may elect to make a portion or the entirety of their
                  investment by way of a philanthropic donation (endorsed by a
                  state or territory government.
                </p>
                <h4>E. Entrepreneur stream</h4>
                <p>
                  This provisional visa lets you carry out entrepreneurial
                  activities in Australia.
                </p>
                <h6>You must</h6>
                <ul>
                  <li>Be under 55 </li>
                  <li>
                    Be nominated by an Australian State or Territory government
                    agency, before being invited to apply for this visa.
                  </li>
                  <li>Have competent English</li>
                  <li>
                    Have a complying entrepreneur activity. You must be
                    undertaking, or proposing to undertake, a complying
                    entrepreneur activity in Australia. You must have a genuine
                    intention to continue this activity. A complying
                    entrepreneur activity must relate to an innovative idea that
                    will lead to:
                    <ul>
                      <li>
                        the commercialisation of a product or service in
                        Australia, or
                      </li>
                      <li>
                        the development of an enterprise or business in
                        Australia
                      </li>
                      <li>residential real estate</li>
                      <li>labour hire</li>
                      <li>
                        purchase of an existing enterprise or a franchise in
                        Australia
                      </li>
                    </ul>
                  </li>
                  <li>
                    Receive funding from an approved entity. You must have one
                    or more legally enforceable agreements to receive funding
                    with a total of at least AUD200,000. The funding must be
                    from one of the following entities:
                    <ul>
                      <li>Commonwealth Government agency</li>
                      <li>State or Territory government agency</li>
                      <li>
                        Publicly funded research or innovation organisation
                      </li>
                      <li>
                        Higher education provider specified as a Table A or
                        Table B provider under Part 2-1 of the Higher Education
                        Support Act 2003
                      </li>
                      <li>
                        Investor registered as an Australian Venture Capital
                        Limited Partnership or Early Stage Venture Capital
                        Limited Partnership
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  At least 10% of the funding must be payable to the
                  entrepreneurial entity within 12 months of the activity
                  starting in Australia. This must be in the agreement. The
                  entity that will conduct the activity (the entrepreneurial
                  entity) must be either an individual (yourself), a company or
                  a partnership. You must have held at least 30% interest in
                  your entrepreneurial entity when you entered into the
                  agreement. You must also have a business plan for the
                  entrepreneurial entity. This business plan should note how
                  your innovative idea will lead to:{" "}
                </p>
                <ul>
                  <li>
                    the commercialisation of a product or service in Australia,
                    or
                  </li>
                  <li>
                    the development of an enterprise or business in Australia
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Business Innovation and Investment (Permanent) visa (subclass 888)" />
              </div>
              <p>
                This visa is the second stage of the Business Innovation and
                Investment (Provisional) visa (subclass 188). This is a
                permanent stage for the initial 188 visa, allowing you to manage
                your business and investments in Australia for an indefinite
                period.
              </p>
              <p>
                Just like the 188 visa, the 888 visa has five streams depending
                on your businesses level of contribution to the Australian
                economy. Depending on which stream your 188 visa was granted
                under will determine which stream of the 888 visa you qualify
                for.{" "}
              </p>
              <p>
                In order to apply for the 888 visa, you will not be required to
                meet a Points Test, also you do not have to submit a new EOI or
                be invited to apply by the Department. However, you will still
                need to be nominated by an Australian State or Territory, or by
                Austrade. You will also meet the additional requirements,
                depending on the stream you held in your 188 provisional visa.
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="faq-section section bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Business Talent (Permanent) visa (subclass 132)" />
              </div>
              <p>
                This visa allows you to establish a new or develop an existing
                business in Australia permanently.
              </p>
              <h4>Significant Business History stream</h4>
              <p>
                This visa is for experienced business owners, to operate a new
                or existing business in Australia.
              </p>
              <h6>You must:</h6>
              <ul>
                <li>
                  Be nominated by an Australian State or Territory government
                  agency, before being invited to apply for this visa.
                </li>
                <li>
                  Have a net value of at least AUD1.5 million (lawfully
                  acquired)
                </li>
                <li>
                  Have an annual business turnover of at least AUD3 million for
                  at least 2 of the 4 fiscal years immediately before you are
                  invited to apply
                </li>
                <li>
                  Have a total net assets of at least AUD400,000 as the
                  ownership interest in one or more qualifying businesses for
                  least 2 of the 4 fiscal years immediately before you are
                  invited to apply
                </li>
              </ul>
              <h4>Venture Capital Entrepreneur stream</h4>
              <p>
                This visa is for people who have sourced venture capital funding
                from a member of the Australian Investment Council (AIC)
                (formerly known as the Australian Private Equity and Venture
                Capital Association Limited (AVCAL)).
              </p>
              <h6>You must: </h6>
              <ul>
                <li>
                  Be nominated by an Australian State or Territory government
                  agency, before being invited to apply for this visa.
                </li>
                <li>
                  Have funding of at least AUD1 million from an Australian
                  venture capital firm
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default BusinessInvestment;
