import React from "react";
import { Link } from "react-router-dom";

const SidebarCategories = () => {
  const categories = [
    { name: "Au-pair & Demi-pair" },
    { name: "Australia Information" },
    { name: "Australian Migration" },
    { name: "Internship Program" },
    { name: "Study English" },
    { name: "Study in Australia" },
    { name: "Visa Information" },
    { name: "Working Holiday" },
  ];

  return (
    <div className="sidebar-widget-content">
      <ul className="sidebar-widget-cate-list">
        {categories.map((cat) => {
          return (
            <li key={cat.name}>
              <Link to="/blog">
                <span className="text">{cat.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarCategories;
