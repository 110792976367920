import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import IconBox from "../components/IconBox/IconBox2.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import HomeAboutTwo from "../components/About/HomeAboutFive.jsx";

const StudyTour = () => {
  const IconBoxData = [
    {
      id: "01.",
      title: "English language skills",
      desc: "The immersive nature of a Study Tour gives students the benefit of using and improving English language skills through discussion and innovative learning resources.",
    },
    {
      id: "02.",
      title: "Academic advantage",
      desc: "Students participating in Victorian Study Tours explore new ways of thinking. This clarity and focus is a skill for life and is central to reaching academic potential.",
    },
    {
      id: "03.",
      title: "Leadership ability",
      desc: "Setting and achieving goals, exploring personal strengths and forging new pathways are among the personal attributes Study Tour students gain over their peers.",
    },
    {
      id: "04.",
      title: "New global awareness",
      desc: "The experience of living in an Australian household and joining the local community awakens student appreciation of different cultures and lifestyles.",
    },
    {
      id: "05.",
      title: "Growth in confidence",
      desc: "Study Tour students grow in self-confidence that helps them achieve success.",
    },
  ];
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/education.jpg"
        title="Study Tour"
        content="Home"
        contentTwo="Our Services"
      />
      <div className={`section section-padding-t90-b100`}>
        <div className="container">
          <SectionTitle
            title="5 key benefits of joining a Study Tour in Australia :"
            subTitle="Study Tours in Australia offer students a passport to success. Packed with real educational benefits like English language skills and personal development, Study Tours help students develop into global citizens"
          />

          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {IconBoxData &&
              IconBoxData.map((single, key) => {
                return (
                  <div key={key} className="col mb-6" data-aos="fade-up">
                    <IconBox classOption="box-border" data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="faq-section section section-padding-top bg-primary-blue">
        <div className="container">
          <div className="row row-cols-lg-2 row-cols-1">
            <div className="col mb-6" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="CHOOSING THE RIGHT INSTITUTION"
                  title="Why take a Study Tour?"
                />

                <p>
                  Study Tours put students on the fast train to academic
                  achievement. A renowned learning destination, Australian
                  educators know international students have special goals and
                  aspirations. That is why each Study Tour is created to ensure
                  students enjoy a growth journey from the outset. Study Tours
                  also contribute to students developing the life experience and
                  personal characteristics that set them apart as people. By
                  mapping out and achieving personal ambitions along the way,
                  students discover in themselves the application they need to
                  achieve life learning goals. Each study tour program is
                  unique. Each Study Tour program is crafted to accommodate
                  group learning goals. Hence, despite the duration of the study
                  tour, every student takes home a suitcase full of educational
                  advantages.
                </p>
                <p>
                  Typically, a short-term study tours last between 1-4 weeks and
                  includes:
                </p>
              </div>
            </div>

            <div
              className="col pl-xl-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                Study tour is a unique short-term travel experience that
                combines learning with travelling. During their visit, students
                can improve English skills, experience Australian culture, and
                build international friendship. Key benefits for students is not
                limited to:
              </p>
              <ul>
                <li>Improved English language skill </li>
                <li>Gaining academic advantage</li>
                <li>Discovering leadership ability</li>
                <li>Growing international awareness</li>
                <li>Newfound student confidence</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <div className={`section section-padding-t90-b100`}>
        <div className="container text-center">
          <h2>Study Tour Themes</h2>
          <p>
            To provide students with the utmost educational advantage, a range
            of themed Study Tours is available. While the benefits of the study
            tour remains the same, the details and excursion visited may
            slightly differ. Of course, each study your, regardless of the
            theme, is still unique. Study Tours provide the flexibility to build
            a custom program of your desired duration. A full Study Tour may
            even include several different themes, to give students a whole
            learning experience.
          </p>
          <p>Generally, there are 3 study tour themes offered in Australia:</p>
          <img
            src="https://timest.com.au/wp-content/uploads/2021/02/pexels-mudassir-ali-1619854-1200x400-1.jpg"
            alt="dd"
          />
        </div>
      </div>
      <HomeAboutTwo />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default StudyTour;
