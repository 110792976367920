import React from "react";

const GoogleMap = () => {
  return (
    <div className="google-map-area section text-center section-padding-bottom">
      <div className="container">
        <div className="contact-map-area" data-aos="fade-up">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25215.322014734928!2d144.96343!3d-37.815454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b4e5327097%3A0xf737e2524cc69725!2s343%20Little%20Collins%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1638935494364!5m2!1sen!2sus"
            aria-hidden="false"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
