import React from "react";
import PropTypes from "prop-types";
import BlogItem from "../../components/Blog/BlogItem2";
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const Team = ({ SectionBgColor }) => {
  const TeamData = [
    {
      id: 1,
      image: "images/current/about/team1.png",
      title: "Joseph KIM (김성대)",
      desc: "Sapien neque suspendisse sit eget netus ullamcorper at ipsum placerat arcu porttitor pretium magna feugiat odio.",
      designation: "Melbourne Branch Manager",
    },
    {
      id: 2,
      image: "images/current/about/team2.jpg",
      title: "Keisuke KONDO ( 近藤啓輔)",
      desc: "Consequat luctus morbi egestas turpis nisi, dignissim neque aliquam leo dui, volutpat eu volutpat sagittis tristique.",
      designation: "Japanese Team Manager",
    },
    {
      id: 3,
      image: "images/current/about/team3.png",
      title: "Sally HISLOP (김선미)​",
      desc: "MARN 1383279 Member of MIA | 6408 PIER Qualified Education Agent Counsellor | QEAC J291 Justice of the Peace in NSW | 206737",
      designation: "Migration Agent",
    },
  ];

  return (
    <div className={`section section-padding-t90-b100 ${SectionBgColor}`}>
      <div className="container">
        <SectionTitle
          title="Meet Our Team"
          subTitle="At Time Study, we have a working principle of being trustworthy, and never let the client down. We also have a motto of “don’t give up, until the last minute.” No matter what your problem or difficulty is, Time Study is here to help. Your satisfaction is always what we strive for."
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
          {TeamData &&
            TeamData.map((single, key) => {
              return (
                <div key={key} className="col mb-6" data-aos="fade-up">
                  <BlogItem data={single} key={key} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
Team.propTypes = {
  SectionBgColor: PropTypes.string,
};
Team.defaultProps = {
  SectionBgColor: "section section-padding-t90-b100",
};

export default Team;
