import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";

const HomeAboutThree = () => {
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="Supporting Subheading"
                  title="Professional Year Program"
                />
                <p>
                  The Professional Year is a structured professional development
                  program combining formal learning and workplace experience.
                  Professional Year (PY) Programs are gazetted by the Department
                  of Immigration and Border Protection (DIBP) to address
                  Australia’s skills shortage through enhancing the
                  employability of recent international graduates in specific
                  professions.
                </p>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={process.env.PUBLIC_URL + "images/current/intern/2.jpg"}
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/1.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="English for Employment" />
                <ul>
                  <li>
                    familiarise participants with the norms and values in the
                    Australian workplace as well as the Australian employment
                    market and workplace culture
                  </li>
                  <li>
                    teach participants how to communicate effectively and
                    professionally in the Australian workplace with colleagues,
                    managers and clients
                  </li>
                  <li>
                    include an internship (work experience) to educate
                    participants about all aspects of work practices in an
                    Australian company
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="The Professional Year must have been undertaken for a period of at least 12 months in the 48 months immediately before applying and be in either:​" />
                <ul>
                  <li>the applicants nominated skilled occupation</li>
                  <li>a closely related skilled occupation</li>
                </ul>
                <p>
                  Points (for migration purpose) can only be awarded for
                  Professional Year courses that have been specified by the
                  minister.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="The following Professional Year courses have been specified by the minister:​" />
                <ul>
                  <li>Computing science</li>
                  <li>Accounting</li>
                  <li>Engineering</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutThree;
