import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import HomeAboutThree from "../components/About/HomeAboutSeven.jsx";
import CallToAction from "../container/CallToAction/CallToAction";

const EnglishCourse = () => {
  return (
    <React.Fragment>
      <SEO title="Universities and Higher Education | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/education.jpg"
        title="Universities and Higher Education"
        content="Home"
        contentTwo="Our Services"
      />
      <div className={`section section-padding-t90-b100`}>
        <div className="container text-center">
          <h2>Universities and Higher Education</h2>
          <p>
            The Australian higher education system consists of independent,
            self-governing public and private universities and higher education
            institutions that award higher education qualifications. There are
            38 public universities and 3 private universities in Australia.
            Seven out of one hundred top universities in the world are
            Australian.
          </p>
        </div>
      </div>
      <div className={`section section-padding-t90-b100`}>
        <div className="container text-left">
          <h2>Australia University Rankings</h2>
          <p>
            According to Australian Education Network, the top 25 universities
            in 2017 are listed as follows. More information can be accessed
            through:{" "}
            <a href="http://www.australianuniversities.com.au/rankings">
              http://www.australianuniversities.com.au/rankings
            </a>
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <h5>Aus Rank</h5>
                </th>
                <th scope="col">
                  {" "}
                  <h5>University</h5>
                </th>
                <th scope="col">
                  <h5>World Rank 2017</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h5>1</h5>
                </td>
                <td>
                  <h5>Australian National University</h5>
                </td>
                <td>
                  <h5>20</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>2</h5>
                </td>
                <td>
                  <h5>University of Melbourne</h5>
                </td>
                <td>
                  <h5>=41</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>3</h5>
                </td>
                <td>
                  <h5>University of Sydney</h5>
                </td>
                <td>
                  <h5>50</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>4</h5>
                </td>
                <td>
                  <h5>University of New South Wales</h5>
                </td>
                <td>
                  <h5>45</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>5</h5>
                </td>
                <td>
                  <h5>University of Queensland</h5>
                </td>
                <td>
                  <h5>=47</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>6</h5>
                </td>
                <td>
                  <h5>Monash University</h5>
                </td>
                <td>
                  <h5>60</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>7</h5>
                </td>
                <td>
                  <h5>University of Western Australia</h5>
                </td>
                <td>
                  <h5>=93</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>8</h5>
                </td>
                <td>
                  <h5>University of Adelaide</h5>
                </td>
                <td>
                  <h5>=109</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>9</h5>
                </td>
                <td>
                  <h5>University of Technology Sydney</h5>
                </td>
                <td>
                  <h5>176</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>10</h5>
                </td>
                <td>
                  <h5>University of Newcastle</h5>
                </td>
                <td>
                  <h5>=224</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>11</h5>
                </td>
                <td>
                  <h5>University of Wollongong</h5>
                </td>
                <td>
                  <h5>232</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>12</h5>
                </td>
                <td>
                  <h5>Queensland University of Technology</h5>
                </td>
                <td>
                  <h5>=247</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>=13</h5>
                </td>
                <td>
                  <h5>Curtin University</h5>
                </td>
                <td>
                  <h5>262</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>=13</h5>
                </td>
                <td>
                  <h5>Macquarie University</h5>
                </td>
                <td>
                  <h5>=240</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>=13</h5>
                </td>
                <td>
                  <h5>RMIT University</h5>
                </td>
                <td>
                  <h5>=247</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>16</h5>
                </td>
                <td>
                  <h5>University of South Australia</h5>
                </td>
                <td>
                  <h5>=279</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>17</h5>
                </td>
                <td>
                  <h5>University of Tasmania</h5>
                </td>
                <td>
                  <h5>313</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>18</h5>
                </td>
                <td>
                  <h5>Deakin University</h5>
                </td>
                <td>
                  <h5>=293</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>19</h5>
                </td>
                <td>
                  <h5>Griffith University</h5>
                </td>
                <td>
                  <h5>=325</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>20</h5>
                </td>
                <td>
                  <h5>James Cook University</h5>
                </td>
                <td>
                  <h5>=367</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>21</h5>
                </td>
                <td>
                  <h5>Swinburne University of Technology</h5>
                </td>
                <td>
                  <h5>421-430</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>22</h5>
                </td>
                <td>
                  <h5>La Trobe University</h5>
                </td>
                <td>
                  <h5>360</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>23</h5>
                </td>
                <td>
                  <h5>Bond University</h5>
                </td>
                <td>
                  <h5>431-440</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>24</h5>
                </td>
                <td>
                  <h5>Flinders University</h5>
                </td>
                <td>
                  <h5>551-600</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>25</h5>
                </td>
                <td>
                  <h5>Western Sydney University</h5>
                </td>
                <td>
                  <h5>551-600</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CallToAction />
      <div className={`section section-padding-t90-b100`}>
        <div className="container text-center">
          <h2>Qualifications</h2>
          <p>
            These are the different qualifications you can attain by completing
            university or higher education program:
          </p>
        </div>
      </div>
      <HomeAboutThree />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default EnglishCourse;
