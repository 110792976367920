import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const HomeAboutTwo = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Sydney" />
                <p>
                  Rated third best city in the world to live in (2011 City Rep
                  Trak index), it’s no surprise that students from over 200
                  international backgrounds come to live and study in Sydney.
                  Renowned for its unique combination of urban and outdoor
                  living; culturally diverse and friendly community; Sydney is a
                  fun and inspiring city in which to live and study. The
                  beautiful harbour, pristine beaches and temperate climate mean
                  you will be able to enjoy an active, outdoor lifestyle; while
                  a vibrant social and cultural scene, with museums, theatres,
                  galleries and hundreds of restaurants, bars and cafes will
                  give you the chance to meet and make new friends, no matter
                  what your interests. <br />
                  Sydney is also Australia’s number one business destination.
                  With many multinational companies located here, it accounts
                  for 30% of the country’s economic activity. Set around a
                  stunning natural harbour, Sydney offers the best of urban and
                  outdoor living. A temperate climate means that you have all
                  year round to enjoy it. Many campuses are located in the
                  vibrant central business district, putting you in the heart of
                  the action, amidst restaurants, cinemas, theatres and shops.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/about/2.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/about/3.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Melbourne" />
                <p>
                  An inaugural ranking of the most student-friendly cities in
                  the world, Melbourne was ranked fourth, just behind Paris,
                  London and Boston. The research group behind the ‘QS World
                  University Rankings’ also listed Sydney at No. 6 – making
                  Australia the only country in the world with two cities in the
                  top 10! Melbourne’s high rating comes despite several years of
                  bad international publicity, following a series of attacks on
                  Indian students in 2009. Furthermore, the strong Australian
                  dollar and strict student visa requirements resulted in a
                  recent drop in international enrolments, which the matter is
                  now currently being reviewed. <br />
                  Harry Liu, who moved to Melbourne from Beijing two years ago
                  to study a master of computing at RMIT, couldn’t agree more.
                  ”I love the people here and enjoy my study … it is not cheap
                  but not too expensive for me,” says the 25-year-old, who
                  shares a house in Carnegie with four other students. He says
                  he enjoys Melbourne’s restaurants and karaoke bars the most.
                  ”I try a lot of Chinese restaurants, but also some Italian
                  food. And I love going to karaoke a lot with my friends,” he
                  says.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Brisbane" />
                <p>
                  Brisbane is the capital city of Queensland and the third
                  largest city in Australia. Consequently, It boasts of all the
                  attractions and facilities of a large city. Brisbane provides
                  world-class educational facilities, mixed with a vibrant and
                  active lifestyle. Brisbane is a friendly multicultural river
                  city that offers the best of bustling urban life, while having
                  many relaxing escapes within short driving distances.
                  Throughout the year, you can get involved in a number of
                  annual festivals and events that take place in the city,
                  including the Brisbane Seafood and Wine Festival, the Mercedes
                  Benz Fashion Festival, the Brisbane International Film
                  Festival and the Valley Fiesta. <br />
                  For those looking for adventure, Brisbane offers a range of
                  exciting experiences including motorcycle tours, adventure
                  parks, hot air ballooning, river cruises, kayaking, skydiving
                  and go-karting, as well as various walking and mountain biking
                  tracks and national parks. Considered a cultural hub when it
                  comes to arts and entertainment, Brisbane features a full
                  calendar of opera, ballet, musicals, concerts, art
                  exhibitions, with a variety of theatres to choose from.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/about/4.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutTwo;
