import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import Faq from "../container/Faq/Faq";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import IconBox from "../components/IconBox/IconBox2.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import HomeAboutTwo from "../components/About/HomeAboutTwo.jsx";

const AustraliaEducation = () => {
  const IconBoxData = [
    {
      id: "01.",
      title: "Global Education Powerhouse",
      desc: "Australia is a leading global education powerhouse with some of the world’s best facilities and educators; providing local and international students with a range of quality study options.",
    },
    {
      id: "02.",
      title: "Top International Students Destination",
      desc: "Australia is the 3rd most popular international student destination in the world despite having a population of only 23 million.",
    },
    {
      id: "03.",
      title: "Broad Options",
      desc: "You can learn anything in Australia. There are more than 22,000 courses available which are offered by 1,100 institutions.",
    },
    {
      id: "04.",
      title: "Where The Top Universities are Located",
      desc: "7 out of 100 top universities in the world are Australian ranked in the latest QS ranking (2014-15). The universities are: The Australian National University (25th) / The University of Melbourne (33th) / The University of Sydney (37th) / The University of Queensland (43th) / The University of New South Wales (48th) / Monash University (70th)",
    },
    {
      id: "05.",
      title: "Top University System Ranking",
      desc: "The Australian University System Ranking is 8th in the world which is ahead of the UK / Germany / Japan and the Netherlands.",
    },
    {
      id: "06.",
      title: "Best Student Cities",
      desc: "5 out of 30 QS Best Student Cities in the world (2015) are in Australia. They are Melbourne (2nd) / Sydney (4th) / Canberra (21st) / Brisbane (23rd) / and Adelaide (29th).",
    },
    {
      id: "07.",
      title: "Great Strengths in Numerous Study Areas",
      desc: "Australian universities feature in the top 50 ranked universities in the world in the following study areas: Arts & Humanities / Clinical / Pre-Clinical & Health / Engineering & Technology / Life Sciences / Physical Science and Social Science.",
    },
    {
      id: "08.",
      title: "Notable Alumni",
      desc: "There are more than 2,500,000 international alumni from Australian institutions that are making a difference around the world. Among them are 15 Nobel Prize laureates.",
    },
    {
      id: "09.",
      title: "Making a Difference in the World",
      desc: "Over 1,000,000,000 people around the world rely on Australian innovations every day. Examples include: Penicillin/ IVF/ Ultrasound / Wi-Fi / Bionic Ear/ Cervical Cancer Vaccine/ and the Black Box.",
    },
  ];
  return (
    <React.Fragment>
      <SEO title="Education System Agents in Australia | Time Study" />
      <Header />
      <Breadcrumb
        image="images/current/bg/education.jpg"
        title="Australia Education System"
        content="Home"
        contentTwo="Education System"
      />
      <div className={`section section-padding-t90-b100`}>
        <div className="container">
          <SectionTitle
            title="Why Study in Australia?"
            subTitle="Here are 9 interesting facts about studying in Australia:"
          />

          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            {IconBoxData &&
              IconBoxData.map((single, key) => {
                return (
                  <div key={key} className="col mb-6" data-aos="fade-up">
                    <IconBox classOption="box-border" data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Faq />
      <CallToAction />
      <div className={`section section-padding-t90-b100`}>
        <div className="container text-center">
          <h2>Student Life</h2>
          <p>
            STUDENT LIFE IS ALWAYS EXCITING AND FULL OF SURPRISES WHEN YOU STUDY
            IN AUSTRALIA. THERE ARE MANY VARIOUS PLACES TO EXPLORE AND
            ACTIVITIES TO ENGAGE IN WHILE YOU ARE COMPLETING YOUR DEGREE. HERE
            ARE SOME EXAMPLES OF STUDENT LIFE YOU WILL EXPERIENCE IN DIFFERENT
            AUSTRALIAN CITIES
          </p>
          <img
            src="https://timest.com.au/wp-content/uploads/2020/10/e5.jpg"
            alt="dd"
          />
        </div>
      </div>
      <HomeAboutTwo />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AustraliaEducation;
