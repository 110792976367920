import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BlogDetails = ({ data }) => {
  return (
    <div className="blog-3 blog-details col" data-aos="fade-up">
      <div className="thumbnail">
        <img className="w-100" src={data.image.url} alt="Blog Image" />
      </div>
      <div className="info">
        <ReactMarkdown children={data.content} plugins={[remarkGfm]} />

        <ul className="meta mb-0 mt-12">
          <li>
            <i className="fal fa-pencil-alt"></i>
            Written By {data.author}
          </li>
          <li>
            <i className="far fa-calendar"></i>
            {data.date}
          </li>
          <li className="media">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <i className="fas fa-share-alt"></i>Share this post
            </Link>
            <div className="list">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.tumblr.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-tumblr-square"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

BlogDetails.propTypes = {
  data: PropTypes.object,
};

export default BlogDetails;
