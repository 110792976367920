import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../Accordion";

const AccordionWrap = () => {
  return (
    <div className="agency-accordion max-mb-n30">
      <Accordion>
        <AccordionItem id="one">
          <AccordionTitle id="one">Who is it for?</AccordionTitle>
          <AccordionContent id="one">
            Foundation studies are designed for people who are returning to
            study, who may not have any qualifications, or for English as a
            second language student. Foundation Studies in Australia gives
            international students the opportunity to study a pre-university
            year within a fully-accredited specialist program as the first step
            to completing tertiary studies.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="two">
          <AccordionTitle id="two">What will I study?</AccordionTitle>
          <AccordionContent id="two">
            It is equivalent to Australia’s Year 12 (the pre-university year).
            It is taught in English, but includes comprehensive English
            instruction and support where that is required. Students can choose
            from a range of program options which have been specially developed
            to meet university requirements
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="three">
          <AccordionTitle id="three">Where does it lead?</AccordionTitle>
          <AccordionContent id="three">
            The course develops confidence and creativity in students and equips
            them to discover, extend and express themselves, as well as to
            achieve their personal, vocational and social goals. Students
            comprehend the world, relate well to others, and are high academic
            achievers. Students can proceed directly to University if their
            results for the program are good enough.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionWrap;
