import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../Accordion";

const AccordionWrap = () => {
  return (
    <div className="agency-accordion max-mb-n30">
      <Accordion>
        <AccordionItem id="one">
          <AccordionTitle id="one">
            What are the differences between VET qualifications and Higher
            Education qualifications?
          </AccordionTitle>
          <AccordionContent id="one">
            Higher-level education such as universities and VET providers both
            offer qualifications that are nationally and often internationally
            recognised. Although there are some overlapping qualification
            levels, in general, VET qualifications aim to provide a practical,
            work-oriented skills base. There are also differences in the grading
            and assessment process, with VET qualifications using
            competency-based assessment, and Higher Education qualifications
            generally using a grading approach to assessment. Compared to
            higher-level education, VET courses are also relatively much
            cheaper.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="two">
          <AccordionTitle id="two">
            Competency Based Training in VET
          </AccordionTitle>
          <AccordionContent id="two">
            Competency Based Training is designed to provide learners with an
            opportunity to demonstrate their ability in a certain task. In the
            framework of VET, this is often a workplace task, referred to as a
            Unit of Competency. Your qualification is made up of these workplace
            tasks. Competency Based Training works by deeming a learner either
            competent, or not yet competent at the end of the learning pathway.
            Unlike in higher education, it is not a graded qualification.
            Instead, learners are simply required to demonstrate the ability to
            complete a task, activity or project. A learner will need to be
            deemed competent in all units to achieve their full qualification.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="three">
          <AccordionTitle id="three">VET Course Fees</AccordionTitle>
          <AccordionContent id="three">
            Generally, VET course fee may range between AUD $4000 to $22,000
            annually. However, this fee may vary depending on the chosen
            institution, location, and course. Additional costs like access to
            institution facilities and course materials can be included.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="four">
          <AccordionTitle id="four">Key statistics about VET</AccordionTitle>
          <AccordionContent id="four">
            According to the National Centre for Vocational Education Research
            in 2019, among all students who graduated from VET in Australia:
            65.8% had an improved employment status after training 66.7% were
            employed before training; of these, 18.0% were employed at a higher
            skill level after training, 33.3% were not employed before training;
            of these, 46.8% were employed after training, 88.1% were satisfied
            with the overall quality of training.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionWrap;
