import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

const SidebarPost = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://time-study.herokuapp.com/blogs`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div className="sidebar-widget-content">
      <ul className="sidebar-widget-list-post">
        {show === true ? (
          <Skeleton count={5} />
        ) : (
          <>
            {data.slice(0, 4).map((value) => {
              return (
                <li key={value.id}>
                  <Link
                    to={{
                      pathname: `/blog-details/${value.id}`,
                      state: value,
                    }}
                  >
                    {/* <div style={{ display: "flex" }}>
                      <div>
                        <img
                          src={value.image.url}
                          style={{ width: "200px" }}
                          alt="vfvfv"
                        />
                      </div>
                      <div style={{ marginLeft: "10px", fontSize: "16px" }}>
                        {value.title}
                      </div>
                    </div> */}
                    {value.title}
                  </Link>
                </li>
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
};

export default SidebarPost;
