import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Link } from "react-router-dom";

const HomeAboutThree = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="High Standard of Learning"
                  title="Schooling (Under Age)"
                />
                <h4>
                  In general, school education is 13 years and divided into:
                </h4>
                <ul>
                  <li>
                    Primary school – runs for 7 or 8 years, starting at
                    kindergarten/preparatory (early childhood education) through
                    to Year 6 or 7.
                  </li>
                  <li>
                    Secondary school – runs for 3 or 4 years, from Years 7 to 10
                    or 8 to 10.
                  </li>
                  <li>
                    Senior secondary school – runs for 2 years, Years 11 and 12.
                  </li>
                </ul>
                <p>
                  Early childhood education such as kindergarten/preparatory is
                  intended to provide care and supervision of children, to
                  prepare children for school, and to ensure that children are
                  able to effectively participate in subsequent learning
                  opportunities. Early childhood education is usually conducted
                  in association with community programs, pre-schools and other
                  child-care settings. <br />
                  In Australia, it is compulsory for children to attend primary
                  and secondary school education between the ages of 6 and 16
                  (Year 1 to Year 9 or 10). Primary and secondary education
                  focus on developing essential literacy, numeracy and social
                  skills, and provide foundational knowledge to children about
                  the world around them. <br />
                  Students who complete their senior secondary school program at
                  Year 12 or equivalent are awarded a Senior Secondary
                  Certificate of Education. They then leave school to undertake
                  vocational or higher education courses and/or start work.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/intern/2.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-2">
        <h2>About Schooling Program in Australia</h2>
        <p>
          Prior to university or college, Time Study can help you to enrol in
          local schools. School education is similar across all of Australia
          with only minor variations between states and territories.{" "}
        </p>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/1.jpeg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Key Statistics about School in Australia" />
                <p>
                  According to the Australian Bureau of Statistics (2019):
                  <br />
                  For more information, please check{" "}
                  <Link
                    //style={{ width: "100px" }}
                    to="https://www.abs.gov.au/statistics/people/education/schools/latest-release"
                  >
                    Visit Source
                  </Link>
                  <ul>
                    <li>
                      there were 3,948,811 students enrolled in 9,503 schools.
                    </li>
                    <li>
                      teachers made up 68.5% of in-school full-time equivalent
                      staff.
                    </li>
                    <li>
                      the year 7 to 12 full-time apparent retention rate was
                      84.0%.
                    </li>
                    <li>
                      the average student to teaching staff ratio for all
                      schools was 13.7. The map below shows the student
                      enrolment counts by state and territory and school
                      affiliation in 2019:
                    </li>
                  </ul>
                  source:{" "}
                  <a href="https://www.abs.gov.au/statistics/people/education/schools/latest-release">
                    Visit Source
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Benefits of Schooling in Australia" />
                <p>
                  Australian schools do not just educate students academically.
                  Instead, they also prepare them for life by developing
                  communication skills, self-discipline and respect for
                  themselves, their peers and their world. Schools offer a broad
                  curriculum in the key learning areas such as English,
                  mathematics, studies of society and the environment, science,
                  arts, Languages Other Than English (LOTE), technology, health
                  and physical education. They also believe strongly in the
                  benefits of a rounded education such as teamwork,
                  self-expression and personal development that happens outside
                  the classroom. <br />
                  In Australia, students will enjoy an open and multicultural
                  learning environment that is as personally enriching as it is
                  educational, and develop the skills and qualities needed in
                  the future.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/7.jpg"
                      }
                      alt="ccdc"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/2.jpeg"
                      }
                      alt="cdc"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="High Standard of Learning" />
                <p>
                  Australian schools are among the finest in the world.
                  According to the World Population Review, Australia is in the
                  top 6 among the world’s best countries for education
                  <a href="https://worldpopulationreview.com/country-rankings/education-rankings-by-country">
                    Visit Source
                  </a>
                  These are a few things which makes education in Australia very
                  valuable:
                  <ul>
                    <li>
                      Small class sizes (a maximum of 30 students in a class)
                    </li>
                    <li>
                      University-trained and qualified teachers and specialist
                      teachers in subject areas.
                    </li>
                    <li>
                      Facilities of a high standard – including a high level of
                      technology, with all schools having computers and internet
                      access.
                    </li>
                    <li>
                      ‘Gifted and talented’ programs to extend students who are
                      high achievers.
                    </li>
                    <li>
                      ‘High Achievement’ programs, which see the top students
                      studying university-level subjects for advance credit
                    </li>
                    <li>
                      Quality assurance frameworks where schools must meet
                      required standards.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Support for School Students​" />
                <p>
                  Australian schools offer a strong welfare structure to ensure
                  the ongoing support of every student such as:
                  <ul>
                    <li>International student coordinators </li>
                    <li>Student welfare team</li>
                    <li>Year advisers</li>
                    <li>School counsellors</li>
                    <li>
                      Careers advisers to assist students with planning and
                      applying for post-secondary study
                    </li>
                    <li>
                      English as a second language support staff and programs
                    </li>
                    <li>Students learn with local Australian students</li>
                    <li>
                      Accommodation in homestays: all host families must have a
                      police check to meet requirements of child protection
                      legislation and homes must meet required standards;
                      students have a 24 hour emergency contact number.{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/8.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/3.jpeg"
                      }
                      alt="cdcdc"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="National Curriculum and Assessment​​" />
                <p>
                  Australia has a National Curriculum for schools developed by
                  the Australian Curriculum, Assessment and Reporting Authority
                  (ACARA). Under the National Curriculum, some periodic
                  assessment are compulsory for students:
                </p>
                <p>
                  The National Assessment Program – Literacy and Numeracy
                  (NAPLAN) is an annual assessment for students in Years 3, 5, 7
                  and 9. The assessments are undertaken nationwide, every year,
                  in the second week in May. NAPLAN tests the sorts of skills
                  that are essential for every child to progress through school
                  and life. It comprises tests in four areas (or ‘domains’):
                  reading, writing, language conventions (spelling, grammar and
                  punctuation), and numeracy. In 2016, the NAPLAN results showed
                  that almost 94% of children have met national minimum
                  standards for their age.
                </p>
                <p>
                  source:{" "}
                  <a href="https://www.nap.edu.au/naplan">
                    https://www.nap.edu.au/naplan
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Other Information" />
                <h4>School qualifications</h4>
                <p>
                  After a successful completion of senior secondary school
                  (Years 11 and 12) students will receive an official
                  certificate of qualification. The certificate will be
                  recognised by all Australian universities, higher education
                  and vocational education and training institutions, as well as
                  many institutions internationally.
                </p>
                <h4>English as The Official Language</h4>
                <p>
                  English is the official language of Australia and hence, the
                  main language of instruction in schools. There may be some
                  bilingual programs offered in some schools.
                </p>
                <h4>Academic Year</h4>
                <p>
                  The Australian academic year typically begins in late January
                  or early February and runs to mid-December. In general,
                  Australian schools have 3 or 4 terms a year, but some states
                  or territories have flexibility about the school year to allow
                  for specific community contexts.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/9.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutThree;
