import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const StudentVisa = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Student Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                The Student Visa (Subclass 500) allows you to remain in
                Australia for up to 5 years for the purpose of full-time study
                in line with your enrolment. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="STUDENT VISA"
                  title="Student visa (Subclass 500)"
                />
                <p>
                  The Student Visa (Subclass 500) allows you to remain in
                  Australia for up to 5 years for the purpose of full-time study
                  in line with your enrolment.
                </p>
                <h6>With this visa you can</h6>
                <ul>
                  <li>
                    participate in an eligible course of study in Australia
                  </li>
                  <li>travel in and out of Australia</li>
                  <li>
                    work up to 40 hours every 2-week period once your course
                    starts
                  </li>
                </ul>
                <h6>You must:</h6>
                <ul>
                  <li>
                    Be enrolled in a course of study and provide evidence, such
                    as Confirmation of Enrolment (COE)
                  </li>
                  <li>Meet English language requirements </li>
                  <li>
                    Have adequate health insurance, such as Overseas Student
                    Health Cover (OSHC)
                  </li>
                  <li>Have enough money for your stay</li>
                  <li>Be a Genuine Temporary Entrant (GTE) </li>
                  <li>Meet character requirement </li>
                  <li>Meet health requirement </li>
                </ul>
                <h6>Stay</h6>
                <p>
                  This is a temporary visa to study up to 5 years. Primary
                  school children enrolled in years 1-4 can only be granted a
                  student visa for a maximum of 2 years.{" "}
                </p>
                <h6>Stay longer</h6>
                <p>
                  To continue studying in Australia, you must apply for a new
                  Student visa. If your Student visa will expire before your
                  graduation you might be eligible for a Visitor visa (subclass
                  600). You will need a letter from your education provider with
                  the date of your graduation. If you hold or held a student
                  visa and completed specific qualifications in Australia in the
                  last six months, you might be eligible for a Temporary
                  Graduate visa (subclass 485).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default StudentVisa;
