import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogClassic = ({ data }) => {
  return (
    <div className="blog-3 col">
      <div className="thumbnail">
        <Link
          to={{
            pathname: `/blog-details/${data.id}`,
            state: data,
          }}
          className="image"
        >
          <img src={data.image.url} alt="Blog Image" />
        </Link>
      </div>
      <div className="info">
        <ul className="meta">
          <li>
            <i className="fal fa-pencil-alt"></i>
            {data.author}
          </li>
          <li>
            <i className="far fa-calendar"></i>
            {data.date}
          </li>
        </ul>
        <h3 className="title">
          <Link
            to={{
              pathname: `/blog-details/${data.id}`,
              state: data,
            }}
          >
            {data.title}
          </Link>
        </h3>
        {/* <div className="desc">
          <p>{data.title}</p>
        </div> */}
        <Link
          to={{
            pathname: `/blog-details/${data.id}`,
            state: data,
          }}
          className="btn btn-primary btn-hover-secondary mt-6"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};
BlogClassic.propTypes = {
  data: PropTypes.object,
};
export default BlogClassic;
