import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import HomeAboutThree from "../components/About/HomeAboutSix.jsx";

const EnglishCourse = () => {
  return (
    <React.Fragment>
      <SEO title="Vocational Education | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/education.jpg"
        title="Vocational Education"
        content="Home"
        contentTwo="Our Services"
      />
      <HomeAboutThree />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default EnglishCourse;
