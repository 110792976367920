import React from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import BlogDetailsContainer from "../container/BlogGrid/BlogDetailsContainer";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const BlogDetails = (props) => {
  let ss = props;
  let data = ss.location.state;
  console.log(data);
  return (
    <React.Fragment>
      <SEO title={data.title} />
      <Header />
      <Breadcrumb
        image="images/current/bg/about.jpg"
        title={data.title}
        content="Home"
        contentTwo={data.title}
      />
      <BlogDetailsContainer data={data} />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

BlogDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default BlogDetails;
