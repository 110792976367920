import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const HomeAboutTwo = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Academic Requirements" />
                <ul>
                  <li>
                    Schools: the requirements vary between schools and depend on
                    which state or territory you intend to study. Academic
                    records such as, previous transcripts and English language
                    test results will be required.
                  </li>
                  <li>
                    Vocational education and training: For most VET
                    institutions, students do not have to sit an entrance exam
                    to enrol to the course. However, some courses may require
                    specific previous work experience or pre-requisite subjects
                    to be taken.
                  </li>
                  <li>
                    University undergraduate: To be accepted into an
                    undergraduate course, you need to have achieved a certain
                    grade or score in your senior secondary qualification
                    (equivalent to Australian Year 12). Acceptable qualification
                    may include ATAR, USFP GPA, GCE A LEVEL 3 Subject,
                    International Baccalaureate, India – CBSE, Canada – OSSD,
                    Norway – Vitnemal, South Africa, SAT without Aps, ACT
                    without Aps, Hong Kong – HKDSE, China – GaoKao and so on.
                    However, some undergraduate courses may also have specific
                    prerequisite subject requirement.
                  </li>
                  <li>
                    University postgraduate: admissions vary from program to
                    program, but at a minimum, entry into a master’s degree at
                    most Australian Universities requires the completion of the
                    equivalent of an Australian bachelor’s degree or graduate
                    diploma/graduate certificate from a recognised tertiary
                    institution, with a minimum credit (65 per cent) average.
                    Qualifications awarded outside Australia can also be used
                    for admission, but equivalencies and entry requirements are
                    determined based on the country, institution and
                    qualification. For international students, it is advisable
                    to contact the International Students Centre in your chosen
                    universities for more information on how your degree average
                    grade equates to average grade for Australian universities.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/17.jpg"
                      }
                      alt="scd"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/18.jpg"
                      }
                      alt="fvfv"
                    />
                  </Tilt>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Visa Requirements" />
                <p>
                  Issued an electronic Confirmation of Enrolment (eCoE)
                  certificate.
                </p>
                <ul>
                  <li>
                    Meet the Genuine Temporary Entrant requirement. Read more
                    about this on the Department of Immigration and Border
                    Protection website
                  </li>
                  <li>
                    Sufficient funds for airfares, course fees and living costs.
                  </li>
                  <li>English language proficiency.</li>
                  <li>Meet health and character requirements.</li>
                  <li>Acceptable Overseas Student Health Cover (OSHC).</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="English Language Requirements​" />
                <p>
                  Under certain circumstances, you may need to provide results
                  of an English language test. It is important for you to know
                  that the English language skill level required by an
                  institution can be different from the level of skill required
                  for your student visa application. You should carefully check
                  student visa information on both the Department of Immigration
                  and Border Protection website and the institution website for
                  any English language requirements.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/19.jpg"
                      }
                      alt="jbj"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/current/services/14.jpg"
                      }
                      alt="fvfv"
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Overseas Student Health Cover" />
                <h5>What is OSHC:</h5>
                <p>
                  OSHC (Overseas Student Health Cover) is insurance to assist
                  international students meet the costs of medical and hospital
                  care that they may need while in Australia. OSHC will also pay
                  limited benefits for pharmaceuticals and ambulance services.
                  As an international student in Australia, you are required to
                  have OSHC for the entire duration of your study in Australia.
                </p>
                <h5>Who has to take out OSHC?</h5>
                <p>
                  People who must take out OSHC are overseas students
                  undertaking formal studies in Australia and their dependents
                  (spouses and children under 18 years old).
                </p>
                <h5>What does OSHC cover?</h5>
                <p>
                  OSHC policies assist to cover the cost of hospital and medical
                  treatment. This covers ambulance services and pharmaceuticals,
                  but restrictions apply (limited to $50 per pharmaceutical item
                  to a maximum of $300 a year for single membership). OSHC does
                  not pay for general treatment such as dental, optical or
                  physiotherapy. If you require cover for these treatments, you
                  may take Extra OSHC provided by an OSHC provider or general
                  treatment cover with any Australian private health insurer.
                  You can also choose to supplement OSHC with other insurance
                  such as international travel insurance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutTwo;
