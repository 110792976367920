import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const FamilyPartner = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Family & Partner Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                There are several options to bring your family to Australia. If
                you are an Australian citizen, Australian permanent resident or
                eligible New Zealand citizen, you may sponsor children, fiancés,
                partners and parents and to join you in Australia. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="FAMILY & PARTNER"
                  title="Prospective Marriage visa (Subclass 300)"
                />
                <p>
                  There are several options to bring your family to Australia.
                  If you are an Australian citizen, Australian permanent
                  resident or eligible New Zealand citizen, you may sponsor
                  children, ffiancés, partners and parents and to join you in
                  Australia.
                </p>
                <h4>Prospective Marriage visa (Subclass 300)</h4>
                <p>
                  The Prospective Marriage visa is also known as a Fiancé visa.
                  This visa is for people who want to come to Australia to marry
                  their prospective spouse. It is a temporary visa for 9 months.
                  You must be outside Australia when you lodge your application
                  and when the visa is granted. You can have the wedding in any
                  country; the wedding doesn ot need to be in Australia. This
                  visa allows you to work, study and apply for a Partner Visa
                  after your marriage in Australia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Partner visa (onshore) (Subclass 820 and 801)" />
              </div>
              <p>
                This partner visa is for the applicants who are in Australia.
                Just like the above offshore partner visa, it is a two stage
                process.
              </p>
              <h4>Partner visa (Temporary) (Subclass 820)</h4>
              <p>
                This visa lets the de facto partner or spouse of an Australian
                citizen, Australian permanent resident or eligible New Zealand
                citizen live in Australia temporarily.{" "}
              </p>
              <ul>
                <li>It is a temporary visa</li>
                <li>
                  It leads directly to the Partner visa (Permanent) (Subclass
                  801)
                </li>
                <li>
                  You must be in Australia when you apply and when the visa is
                  granted
                </li>
              </ul>
              <h4>Partner visa (Permanent) (Subclass 801)</h4>
              <ul>
                <li>This a permanent visa</li>
                <li>You must hold a temporary Partner visa (subclass 820)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="faq-section section bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Partner visa (offshore) (Subclass 309 and 100)" />
              </div>
              <p>
                This offshore partner visa is for applicants who are outside
                Australia. It is a two stage process. Unless you are in a long
                term relationship, you will lodge a permanent Partner (Migrant)
                visa (Subclass 100) 2 years after you lodged your temporary
                Partner (Provisional) visa (subclass 309). You will need to
                provide further documents for this assessment.
              </p>
              <h4>Partner visa (Temporary) (Subclass 309)</h4>
              <p>
                This visa lets the de facto partner or spouse of an Australian
                citizen, Australian permanent resident or eligible New Zealand
                citizen live in Australia temporarily.{" "}
              </p>
              <ul>
                <li>It is a temporary visa</li>
                <li>
                  It leads directly to the Partner visa (Permanent) (Subclass
                  801)
                </li>
                <li>
                  You must be in Australia when you apply and when the visa is
                  granted
                </li>
              </ul>
              <h4>Partner visa (Permanent) (Subclass 100)</h4>
              <ul>
                <li>This a permanent visa</li>
                <li>You must hold a temporary Partner visa (subclass 820)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Parent Visa" />
              </div>
              <p>
                TParents of Australian citizens, Permanent Residents or eligible
                New Zealand Citizens can apply for a Parent Visa if they meet
                the balance of family test and can be sponsored by at least one
                of their children living in Australia.
              </p>
              <ul>
                <li>
                  At least half of your children are living in Australia, or
                  more children are living in Australia (as Australian citizens
                  or permanent residents or eligible New Zealand citizens) than
                  overseas;{" "}
                </li>
                <li>
                  You will be sponsored by one of your children in Australia who
                  is an Australian citizen, ‘settled’ permanent resident or
                  eligible New Zealand citizen.
                </li>
              </ul>
              <h6>Non-Contributory Parent Visa </h6>
              <p>
                This permanent visa lets a parent of a settled Australian
                citizen, Australian permanent resident or eligible New Zealand
                citizen move to Australia.
              </p>
              <h4>Subclass 103 Parent visa</h4>
              <h6>You must:</h6>
              <ul>
                <li>
                  Be outside Australia at the time of lodgement and decision
                </li>
                <li>
                  Be able to obtain an assurance of support. An assurance of
                  support assures us that you will not have to rely on
                  government assistance after you enter Australia on this visa
                </li>
              </ul>
              <h4>Subclass 804 Aged Parent visa</h4>
              <h6>You must:</h6>
              <ul>
                <li>Be in Australia at the time of lodgement and decision</li>
                <li>Be old enough to receive the age pension in Australia</li>
                <li>be able to obtain an assurance of support</li>
              </ul>
              <p>
                Assurance of support is AUD5,000 for the main applicant and
                AUD2,000 for a partner. This bond will be held for a 2-year
                period. The bond will be requested when the visa is being
                assessed, which will be after a significant amount of years.
                Processing time is expected upto 30 years.
              </p>
              <h6>Contributory Parent Visa </h6>
              <p>
                You can apply for a permanent visa (subclass 143), costs $47,755
                per parent; or apply temporary visa (subclass 173), costs
                $31,930 per parent, which will let you stay for 2 years. During
                this 2-year period, parents may apply at any time for a
                permanent visa and pay the remaining charge of $15,825.
              </p>
              <h4>Subclass 143 Contributory Parent visa</h4>
              <h6>
                You can move to or stay in Australia as a permanent resident.
                You must:{" "}
              </h6>
              <ul>
                <li>Be in Australia at the time of lodgement and decision</li>
                <li>Be old enough to receive the age pension in Australia</li>
                <li>be able to obtain an assurance of support</li>
              </ul>
              <h6>Subclass 173 Contributory Parent (Temporary) visa</h6>
              <p>
                You can live in Australia for up to 2 years, and apply for a
                permanent Contributory Parent visa (subclass 143). <br />{" "}
                Assurance of support is AUD10,000 for the main applicant and for
                any adult secondary applicant it is AUD4,000. This bond will be
                held for the 10-year period.
              </p>
              <h6>Contributory Aged Parent Visa </h6>
              <p>
                You can apply for a permanent visa (subclass 864), costs $47,755
                per parent; or apply temporary visa (subclass 884), costs
                $31,930 per parent, which will let you stay for 2 years. During
                this 2-year period, parents may apply at any time for a
                permanent visa and pay the remaining charge of $15,825.
              </p>
              <h4>Subclass 864 Contributory Aged Parent visa</h4>
              <p>
                You can move to or stay in Australia as a permanent resident.
                You must:{" "}
              </p>
              <ul>
                <li>Be in Australia at the time of lodgement and decision</li>
                <li>Be old enough to receive the age pension in Australia</li>
                <li>be able to obtain an assurance of support</li>
              </ul>
              <p>
                This visa will let you enrol in Australia’s public health care
                scheme, Medicare.
              </p>
              <h4>Subclass 864 Contributory Aged Parent visa</h4>
              <p>
                You can live in Australia for up to 2 years, and apply for a
                permanent Subclass 864 Contributory Aged Parent visa. <br />{" "}
                Assurance of support is AUD10,000 for the main applicant and for
                any adult secondary applicant it is AUD4,000. This bond will be
                held for the 10-year period.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Sponsored Parent (Temporary) visa (subclass 870)" />
              </div>
              <h4>Sponsored Parent (Temporary) visa (subclass 870)</h4>
              <p>
                Once a sponsorship application is approved, a sponsored parent
                is able to apply for a Sponsored Parent (Temporary) visa.
              </p>
              <h4>Partner visa (Temporary) (Subclass 309)</h4>
              <p>
                The visa provides parents with a new pathway to temporarily
                reunite with their children in Australia, while ensuring that
                taxpayers are not required to cover additional costs. The visa
                responds to community concerns about the limited number of
                Parent places in the migration program and associated lengthy
                waiting periods.
              </p>
              <p>
                You can visit Australia for up to 3 or 5 years at a time and can
                apply for further visas to visit up to a maximum period in
                Australia of 10 years. However, you cannot work in Australia.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default FamilyPartner;
