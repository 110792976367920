import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";

const HomeAboutTwo = () => {
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Science and Technology" />
                <h5>
                  Penguin Mission and Penguin Parade – Phillip Island Nature
                  Park
                </h5>
                <p>
                  A learning adventure in which students discover the life of
                  the little penguin and how the nature park manages their
                  habitat.
                  <br />
                  Highlights include Penguin Parade at dusk, when hundreds of
                  little penguins emerge from the sea. Students take home a
                  penguin foot plaster cast and bandana designed by their group.
                </p>
                <h5>
                  Mission to the Orbiting Space Lab – Victorian Space Science
                  Education Centre{" "}
                </h5>
                <p>
                  Students become research scientists and Mission Controllers
                  aboard an orbiting space lab. Take fitness tests and make
                  medical assessments before blast off to maintain Space Station
                  safety.Conduct scientific experiments to help humankind
                  understand our earth, solar system and universe.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/4.jpeg"
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/10.jpg"
                    }
                    alt="cdd"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Performing and Visual Arts" />
                <h5>
                  Arts Immersion – Malthouse Theatre, Chunky Move and Australian
                  Centre for Contemporary Art
                </h5>
                <p>
                  A distinctive cross-arts program using recent ACCA work to
                  springboard students into exploring a central theme through
                  visual arts, dance and performance. Students work with
                  practicing artists from each centre over three separate
                  workshops. Video Clip Producer
                </p>
                <h5>The Arts Centre, Digital Learning Hub</h5>
                <p>
                  Students experience first-hand the latest in video editing
                  trends as they combine audio and video to create a music clip.
                  Editing skills are focused on artistic interpretation before
                  the final version is recorded on DVD to take home.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Leadership and Sports" />
                <h5>Student Leadership – WorkshopStride Foundation</h5>
                <p>
                  A one day exploration into what it means to be a leader and
                  the qualities of good leadership. Students learn skills like
                  public speaking, active listening and how to brainstorm as
                  they work together on problem-solving planning and strategy.
                </p>
                <h5>
                  Meet a Lifeguard and Beach Program – Life Saving Victoria
                </h5>
                <p>
                  Meet a Lifeguard is an interactive indoor workshop during
                  which students learn about good decision making to safely
                  enjoy inland, pool and coastal aquatic environments. Safety,
                  rescue and Sunsmart messages are all incorporated.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/11.jpg"
                    }
                    alt="cd"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutTwo;
