import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const TempGradVisa = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Temporary Graduate Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                The Temporary Graduate visa (Subclass 485) that allows
                international students to live, study and work after you have
                finished your studies. You must be under 50 years of age holding
                an eligible visa and have a recent qualification in a
                CRICOS-registered course. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="TEMPORARY GRADUATE VISA"
                  title="Temporary Graduate visa (Subclass 485)"
                />
                <p>
                  A temporary visa that allows international students to live,
                  study and work after you have finished your studies. You must
                  be under 50 years of age holding an eligible visa and have a
                  recent qualification in a CRICOS-registered course.
                </p>
                <h4>Graduate Work stream</h4>
                <p>
                  This visa is for international students who have recently
                  graduated with skills and qualifications that are relevant to
                  specific occupations in Australia needs. It lets you live,
                  study and work in Australia temporarily.
                </p>
                <h6>Stay</h6>
                <p>Usually up to 18 months</p>
                <h6>You must:</h6>
                <ul>
                  <li>
                    Have a qualification relevant to an occupation on the
                    skilled occupation list
                  </li>
                  <li>
                    Be assessed as having skills suitable for an occupation on
                    the skilled occupation list
                  </li>
                  <li>
                    Meet the Australian study requirement in the past 6 months{" "}
                  </li>
                  <li>Meet English language requirements </li>
                  <li>
                    Have adequate health insurance, such as Overseas Visitor
                    Health Cover (OVHC)
                  </li>
                  <li>Meet character requirement </li>
                  <li>Meet health requirement</li>
                </ul>
                <h4>Post-Study Work stream</h4>
                <p>
                  This visa is for international students who have recently
                  graduated with a degree from an Australian institution. It
                  lets you live, work and study in Australia, temporarily.
                </p>
                <h6>Stay</h6>
                <p>
                  Usually between 2 and 4 years, depending on your
                  qualification.
                </p>
                <h6>You must:</h6>
                <ul>
                  <li>
                    Hold an eligible qualification at degree-level or above{" "}
                  </li>
                  <li>
                    Be assessed as having skills suitable for an occupation on
                    the skilled occupation list
                  </li>
                  <li>
                    Meet the Australian study requirement in the past 6 months{" "}
                  </li>
                  <li>Meet English language requirements </li>
                  <li>
                    Have adequate health insurance, such as Overseas Visitor
                    Health Cover (OVHC)
                  </li>
                  <li>Meet character requirement </li>
                  <li>Meet health requirement </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default TempGradVisa;
