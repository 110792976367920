import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const AAT = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="AAT - Visa Refusal & Cancellation"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                We understand that sometimes decisions don’t go your way. We
                work with you to determine the most appropriate options moving
                forward, then assist with planning and preparation for the
                Administrative Appeals Tribunal. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="AAT - REFUSAL & CANCELLATION"
                  title="Migration and Refugee - MR Division"
                />
                <p>
                  Administrative Appeals Tribunal – MR Division is a statutory
                  body with the power to review decisions made by the Department
                  of Home Affairs.{" "}
                </p>
                <p>
                  In conducting a review of migrations decisions, AAT will
                  consider the case afresh and have the power to change the
                  decision under review.{" "}
                </p>
                <p>
                  The Migration Act and the Migration Regulations specify the
                  decisions that AAT can review, who may seek review of a
                  decision, how an application for review must be made, the time
                  limits within which applications for review must be lodged,
                  and the application fee payable.{" "}
                </p>
                <p>
                  The rules about who may seek review of a particular decision,
                  the applicable time limit, and whether a fee is payable vary
                  depending on the type of decision and whether a person is in
                  immigration detention.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AAT;
