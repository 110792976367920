import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import AboutFour from "../container/About/AboutFour";
import ServiceIconBox2 from "../container/service/ServiceIconBox2";
import AboutFive from "../container/About/AboutFive";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import HomeBlog from "../container/BlogGrid/Team";

const AboutUs = () => {
  return (
    <React.Fragment>
      <SEO title="About Us | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images//current/bg/about.jpg"
        title="About Time Study"
        content="Home"
        contentTwo="About Us"
      />
      <AboutFive />
      <AboutFour />
      <ServiceIconBox2 />
      <HomeBlog SectionBgColor="bg-color-1" />
      <CallToActionTwo />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AboutUs;
