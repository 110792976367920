import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import SectionTitleTwo from "../components/SectionTitles/SectionTitleTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const EmployerSponsored = () => {
  return (
    <React.Fragment>
      <SEO title="Study Tour​ | Time Study Pty Ltd" />
      <Header />
      <Breadcrumb
        image="images/current/bg/visa.jpg"
        title="Employer Sponsored Visa"
        content="Home"
        contentTwo="Our Services"
      />
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="VISA SERVICES:"
                  title="Customized Visa Solutions"
                />
              </div>
            </div>

            <div
              className="col-xl-8 col-lg-8 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                This visa is for people with business skills who want to own and
                manage a new or existing business in Australia, or to invest in
                an Australian state or territory. <br />
                Starting with a 30-minute Free Initial Assessment via telephone
                or in person; we will prepare a Letter of Advice if the client
                wishes to proceed with us. The Letter of Advice will contain:
              </p>
              <ul>
                <li>The most suitable visa subclass and its requirementsl </li>
                <li>
                  Fee estimate including our professional fees and the
                  Department’s fee (visa application charge)
                </li>
                <li>Average processing time</li>
                <li>Visa checklist</li>
                <li>
                  Other relevant information such as skills assessment, EOI and
                  the visa conditions that may be imposed on the visa
                </li>
              </ul>
              <p>
                Before signing an agreement on services and their fees, you can
                contact us for more details. We will respond to you as quickly
                as possible within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="EMPLOYER SPONSORED"
                  title="Temporary Skill Shortage visa (subclass 482)"
                />
                <p>
                  This visa lets skilled people with a relevant occupation work
                  in Australia for an employer who can’t find an Australian to
                  do the work.
                </p>
                <h4>Short-term stream</h4>
                <p>
                  This visa enables employers to address labour shortages by
                  bringing in skilled workers where employers can’t source an
                  appropriately skilled Australian worker.
                </p>
                <h6>You must:</h6>
                <ul>
                  <li>Be a Genuine Temporary Entrant (GTE) </li>
                  <li>
                    Be nominated to work in an occupation on the list of
                    eligible short-term skilled occupations by an approved
                    sponsor
                  </li>
                  <li>
                    Have relevant skills, qualifications and employment
                    background to perform occupation
                  </li>
                  <li>
                    Have at least 2 years relevant work experience in your
                    nominated occupation or a related field. Generally, you
                    should have gained your experience on a full-time basis
                    within the last 5 years. The period does not need to be
                    continuous. Equivalent part-time work can be considered but
                    casual work can not be accepted
                  </li>
                  <li>
                    Have a relevant skills assessment if this is required for
                    your occupation
                  </li>
                  <li>Work only for your sponsor or associated entity</li>
                  <li>
                    Meet minimum standards of English language proficiency
                    unless you are exempt
                  </li>
                  <li>Have adequate health insurance</li>
                  <li>Meet health requirement </li>
                  <li>Meet character requirement </li>
                </ul>
                <h6>Stay</h6>
                <p>
                  You can stay for up to 2 years or up to 4 years if an
                  International Trade Obligation (ITO) applies.
                </p>
                <h4>Medium-term stream</h4>
                <p>
                  This visa enables employers to address labour shortages by
                  bringing in skilled workers where employers can’t source an
                  appropriately skilled Australian worker.
                </p>
                <h6>You must:</h6>
                <ul>
                  <li>
                    Be nominated to work in an occupation on the list of
                    eligible medium and long-term skilled occupations by an
                    approved sponsor
                  </li>
                  <li>
                    Have relevant skills, qualifications and employment
                    background to perform occupation
                  </li>
                  <li>
                    Have at least 2 years relevant work experience in your
                    nominated occupation or a related field. Generally, you
                    should have gained your experience on a full-time basis
                    within the last 5 years. The period does not need to be
                    continuous. Equivalent part-time work can be considered but
                    casual work can not be accepted
                  </li>
                  <li>
                    Have a relevant skills assessment if this is required for
                    your occupation
                  </li>
                  <li>Work only for your sponsor or associated entity</li>
                  <li>
                    Meet minimum standards of English language proficiency
                    unless you are exempt
                  </li>
                  <li>Have adequate health insurance</li>
                  <li>Meet health requirement </li>
                  <li>Meet character requirement </li>
                </ul>
                <h6>Stay</h6>
                <p>Up to 4 years</p>
                <h6>Migration Pathway </h6>
                <p>
                  Visa holders can apply for permanent residence, if eligible,
                  through the Employer Nomination Scheme (subclass 186) visa
                  under Temporary Residence Stream.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Employer Nomination Scheme visa (Subclass 186)" />
              </div>
              <p>
                This visa lets skilled workers, who are nominated by their
                employer, live and work in Australia permanently.
              </p>
              <h6>Basic Eligibility</h6>
              <ul>
                <li>You must be under 45 years of age</li>
                <li>You must have the necessary skills for the job</li>
                <li>You must be nominated by an Australian employer</li>
                <li>You must meet health and character requirements</li>
              </ul>
              <h4>Direct Entry stream</h4>
              <p>
                This visa lets skilled workers, who are nominated by an
                employer, live and work in Australia permanently.
              </p>
              <h6>You must</h6>
              <ul>
                <li>
                  Have an occupation that is on the list of eligible skilled
                  occupations{" "}
                </li>
                <li>
                  Have at least 3 years relevant work experience, unless you are
                  exempt
                </li>
                <li>Have a suitable skills assessment for the occupation</li>
                <li>Have at least competent English</li>
              </ul>
              <h4>Temporary Residence Transition stream</h4>
              <p>
                This visa lets skilled workers, who are nominated by their
                employer, live and work in Australia permanently.
              </p>
              <h6>You must</h6>
              <ul>
                <li>
                  Hold a subclass 457, TSS or related Bridging visa A, B or C
                </li>
                <li>
                  Usually, have worked for your employer for at least 3 years
                  full time while holding a subclass 457 or TSS visa
                </li>
                <li>Have at least competent English</li>
              </ul>
              <h4>Labour agreement stream</h4>
              <p>
                This visa lets skilled workers who are nominated by their
                employer live and work in Australia permanently. The Labour
                Agreement stream might be for you if you currently work, or will
                work, for an employer who is party to a labour agreement.
              </p>
              <h6>You must</h6>
              <ul>
                <li>
                  currently work, or will work, for your nominating employer who
                  is party to a labour agreement.
                </li>
                <li>
                  meet the age, skills and English language requirements of the
                  labour agreement
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="faq-section section bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo title="Skilled Employer Sponsored Regional (Provisional) visa (Subclass 494)" />
              </div>
              <p>
                This visa enables regional employers to address identified
                labour shortages within their region by sponsoring skilled
                workers where employers can’t source an appropriately skilled
                Australian worker.
              </p>
              <h6>With this visa, you can</h6>
              <ul>
                <li>
                  Live, work and study only in designated regional areas of
                  Australia for 5 years
                </li>
                <li>Travel to and from Australia for 5 years</li>
                <li>
                  If eligible, apply for Permanent Residence (Skilled Regional)
                  visa (SC 191) after 3 years from the time your visa is granted
                </li>
              </ul>
              <h6>You must</h6>
              <ul>
                <li>Be under 45 years of age</li>
                <li>
                  Be nominated by an employer whose business is lawfully
                  operating in Australia
                </li>
                <li>
                  Have relevant skills, qualifications and work experience
                </li>
                <li>
                  be licensed, registered or a member of a professional body if
                  it is mandatory in the state or territory you intend to work
                  in.
                </li>
                <li>
                  Have at least 3 years relevant work experience in your
                  nominated occupation
                </li>
                <li>
                  Have a relevant skills assessment if this is required for your
                  occupation
                </li>
                <li>have at least competent English </li>
                <li>Meet health requirement </li>
                <li>Meet character requirement </li>
              </ul>
              <h6>Stay</h6>
              <p>Up to 5 years</p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default EmployerSponsored;
