import React from "react";
import { Link } from "react-router-dom";

const SidebarBanner = () => {
  return (
    <div className="sidebar-widget-content">
      <div
        className="sidebar-widget-banner overlay-two"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/breadcrumb-bg.jpg)`,
        }}
      >
        <h3 className="title">Let’s Make Your Dreams Come True!</h3>
        <p>
          We understand the importance of a successful case and how it will add
          value to your future studies, work, family and life.
        </p>
        <Link to={process.env.PUBLIC_URL + "/contact"}>Get Started</Link>
      </div>
    </div>
  );
};

export default SidebarBanner;
