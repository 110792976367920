import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const HomeAboutThree = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="Mastering the International Language"
                  title="English Courses"
                />
                <p>
                  Learning English in Australia through university or college
                  guarantees you the highest quality of education in return for
                  your tuition fee. In addition, speaking english on a
                  day-to-day basis will also help to enhance your skills even
                  better. <br />
                  Australian University and College English courses have been
                  developed to meet the needs of English language learners of
                  all levels. This includes programs for:
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/intern/2.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/1.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="ELICOS​" />
                <p>
                  English Language Intensive Courses for Overseas Students
                  (ELICOS) in Australia provide an excellent introduction to
                  English studies for academic studies, business, or recreation.
                  There are many ELICOS Australian English schools to choose
                  from, ranging from small privately run colleges with small
                  group tuition; secondary schools preparing students for high
                  school studies; to large university or vocational education
                  centres which will offer English in its own right or as a
                  transition to any number of other tertiary or further training
                  programs. <br />
                  In 2019, the EA survey of 166 institutions reported that
                  169,864 ELICOS students studying in Australia experienced a 5%
                  decline from nearly 180,000 in 2018. Most students (70%)
                  studying ELICOS were on student visas followed by visitor
                  (20%), working holiday (6%) and other visas (5%).
                  <br />
                  For more information, please check{" "}
                  <a href="https://internationaleducation.gov.au/research/research-snapshots/Documents/RS-%20ELICOS%20Survey%202019.pdf">
                    Visit Source
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="General English" />
                <p>
                  General English is an ideal way to learn to use English
                  effectively in an everyday environment. Holmes Institute
                  offers 6 levels of general English with all levels carefully
                  designed to cover the macro-skills of speaking, listening,
                  reading and writing as well as grammar and pronunciation
                  practice. General English focuses on developing communication
                  skills to live and work in Australia and abroad. It is mapped
                  to the Common European Framework of Reference for Languages
                  (CEFR) and is offered at all levels – A1 to C2. General
                  English is recommended to be taken in 11 week blocks and its
                  classes are usually 20 hours per week. Classes may be held in
                  the morning, afternoon or evening.
                  <ul>
                    <li>Average class size is 24 students</li>
                    <li>Student/teacher ratio is 11.75</li>
                  </ul>
                </p>
                <h4>General English course focuses on:</h4>
                <ul>
                  <li>
                    Improving Speaking skills, and developing accurate
                    Pronunciation
                  </li>
                  <li>
                    Understanding Grammar, and learning when and how to use it
                  </li>
                  <li>
                    Listening and Reading for the main idea and detailed
                    information
                  </li>
                  <li>Building Vocabulary and Slang</li>
                  <li>Using body language correctly</li>
                  <li>General Writing development</li>
                  <li>
                    Understanding the Culture of English-speaking countries
                  </li>
                  <li>Understanding native and non-native English speakers</li>
                  <li>Conversational English</li>
                </ul>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/2.png"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/3.png"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="IELTS Preparation​​" />
                <p>
                  International English Language Testing System (IELTS) is an
                  international standardised test of English language
                  proficiency. It is jointly managed by University of Cambridge
                  ESOL Examinations, the British Council, and IDP Education Pty
                  Ltd. There are two versions of the IELTS:
                  <ul>
                    <li>
                      The Academic Version: intended for those who want to enrol
                      in universities and other institutions of higher
                      education. It is also used for professional recognition in
                      fields such as medicine.
                    </li>
                    <li>
                      The General Training Version: intended for those planning
                      to undertake non-academic training or to gain work
                      experience.
                    </li>
                  </ul>
                  For studying, working and living in Australia, there are
                  different IELTS requirements. You need to find out what the
                  specific requirement you need to meet.
                  <br />
                  By studying IELTS in Australia, you will gain a huge range of
                  life skills such as problem solving, leading diverse teams of
                  people, and applying your English to real-world situations.
                  With a multicultural mix of students in our educational
                  facilities, you will learn the world’s language in a truly
                  international environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="English for Academic Purposes (EAP)​" />
                <p>
                  English for academic purposes (EAP) entails training students,
                  usually in a higher education setting, to use language
                  appropriately for study. It is one of the most common forms of
                  English for specific purposes (ESP). An EAP program focuses
                  instruction on skills required to perform in an
                  English-speaking academic context across core subject areas
                  generally encountered in a university setting. In common with
                  most language teaching, EAP instruction teaches vocabulary,
                  grammar and the four skills (reading, writing, speaking, and
                  listening). EAP usually tries to tie these to the specific
                  study needs of students.
                  <h4>What is the main benefit of taking an EAP course?</h4>
                  <ul>
                    <li>Over 95% graduation rates.</li>
                    <li>
                      Rapid progress in academic reading, writing, speaking and
                      listening.
                    </li>
                    <li>Practice in lecture and tutorial note-taking.</li>
                    <li>
                      Direct entry to TAFE, undergraduate or postgraduate
                      courses.
                    </li>
                    <li>Extra support for students.</li>
                  </ul>
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/4.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/5.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="English for Travel​​" />
                <p>
                  International travel is difficult without English. Even when
                  you are not visiting an English-speaking country, you will
                  find yourself needing English to speak to hotel staff,
                  immigration officials, taxi drivers, and people on the street.
                  Trips are a wonderful opportunity to practice your English in
                  real-life situations. So before you’ve packed your bags, have
                  a look at our website and contact us for further information
                  about how to enrol in an English course. A Travel English
                  Course will enable you to have a great balance between study
                  and travel. This will give you an opportunity to practice
                  learned English straight away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="English for Employment​" />
                <p>
                  English for Employment program is intended for skilled
                  migrants who need to improve their English to enter the
                  Australian labour market. This program will prepare you for
                  daily communication and provide greater understanding of the
                  Australian workplace culture and job seeking strategies.
                  English for Employment is free for eligible migrants and
                  funded by the NSW Government. Entry is at IELTS 5.5 – 6 or
                  equivalent English level. Classes are delivered both part-time
                  and full-time.
                  <h4>The outline of the content of this course include:</h4>
                  <ul>
                    <li>English language support</li>
                    <li>
                      Developing awareness of workplace culture and trends in
                      Australia
                    </li>
                    <li>Finding your fit in your field of expertise </li>
                    <li>
                      Developing a career portfolio including your resume and
                      cover letter{" "}
                    </li>
                    <li>Strategies to identify employment opportunities </li>
                    <li>Preparing for the job interview </li>
                    <li>Career management skills </li>
                    <li>Marketing yourself effectively </li>
                  </ul>
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-center">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/services/6.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAboutThree;
