import React from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";

const Faq = () => {
  return (
    <div className="faq-section section section-padding-top bg-primary-blue">
      <div className="container">
        <div className="row row-cols-lg-2 row-cols-1">
          <div className="col mb-6" data-aos="fade-up">
            <div className="faq-content">
              <SectionTitleTwo
                subTitle="CHOOSING THE RIGHT INSTITUTION"
                title="We help you design
the life you want."
              />

              <p>
                We assist potential students in giving them detailed information
                about each university or college in Australia. By taking into
                consideration each student’s aspirations and interests, we help
                them choose courses that suit them best at the institution of
                their preference.
              </p>
              <p>
                Once you have selected the course or a direction in which you
                want to make a career in, shortlist the universities that offer
                those courses. This is where we take over the hectic work off
                your mind. We research colleges and universities across
                Australia that offer your favoured courses.
              </p>
              <p>
                Every university or college has a different personality of its
                own which is reflected in their curriculum, teaching styles, and
                the overall experience of its students. To shortlist the right
                institutions, here is our guidance of how to make the right
                decision:
              </p>
            </div>
          </div>

          <div className="col pl-xl-12" data-aos="fade-up" data-aos-delay="300">
            <h5>Differentiate institutions through:</h5>
            <ul>
              <li>Public vs. private institutions</li>
              <li>Specialties</li>
              <li>Teaching quality</li>
              <li>Graduate outcome and satisfaction</li>
              <li>International experiences</li>
              <li>Facilities</li>
              <li>Student services</li>
              <li>Atmosphere</li>
              <li>Cost</li>
            </ul>
            <h5>Research the chosen institutions through:</h5>
            <ul>
              <li>Institution ratings</li>
              <li>Attend an open day</li>
              <li>Word of mouth</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
