import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

const ContactFrom = () => {
  const { register, errors } = useForm({
    mode: "onBlur",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const sendMessage = (e) => {
    setShow(true);
    e.preventDefault();
    const templateParams = {
      from_name: email,
      to_name: name,
      phone: phone,
      message: message,
    };
    emailjs
      .send(
        "service_v9yqgwo",
        "template_gr87kv6",
        templateParams,
        "user_tnI1aXlOKY1uW1NgNDREQ"
      )
      .then(
        function (response) {
          setShow(false);
          alert("Your mail is sent!");
          console.log("SUCCESS!", response.status, response.text);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        },
        function (err) {
          setShow(false);
          alert("Oops... " + JSON.stringify(err));
        }
      );
  };
  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form onSubmit={sendMessage}>
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              placeholder="Your Name *"
              name="name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="email"
              placeholder="Email *"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-md-12 col-12 mb-6">
            <input
              type="text"
              placeholder="Phone *"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message *"
              required
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="col-12 text-center mb-6">
            {show ? (
              <img
                src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif"
                alt="loader"
                style={{ height: "40px" }}
              />
            ) : (
              <button
                type="submit"
                className="btn btn-primary btn-hover-secondary"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;
