import PropTypes from "prop-types";
import React from "react";
// import IconBoxData from "../../data/iconBox/icon-box.json";
import IconBox from "../../components/IconBox/IconBox2.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const ServiceIconBox = ({ classOption }) => {
  const IconBoxData = [
    {
      id: "01.",
      title: "Certified Experience",
      desc: "Time Study has an experience of more than 15 years in dealing with education and migration related matters. We have helped more than 5,000 students who wish to study in Australia.",
    },
    {
      id: "02.",
      title: "Great Support",
      desc: "Time Study aims to provide a transparent and comprehensible service which can be easily understood by our clients. Over the years, we have developed an efficient management system to ensure all applications are done in time with high results being achieved.",
    },
    {
      id: "03.",
      title: "Multi National",
      desc: "Time Study is always near you! We have 5 branch offices which are strategically located around Australia, South Korea, and India. We also offer services in English, Korean, Chinese, Japanese and Vietnamese.",
    },
    {
      id: "04.",
      title: "We Got The Tools",
      desc: "We can relate to you. Some of our agents were once overseas students, so they understand the difficulties and complications associated with living abroad. Hence, they will be able to provide the most timely and useful advice.",
    },
    {
      id: "05.",
      title: "Success Guarantee",
      desc: "A successful application is always our priority. In our 15 years of service, we have gained numerous valuable and positive feedback which helped us to further improve our service.",
    },
    {
      id: "06.",
      title: "Experienced Team",
      desc: "Time Study agents are qualified either as Professional Registered Migration Agents (RMAs) or Professional Education Agents. Our agents have been equipped with excellent training programs and decent experience, making them experts in the field.",
    },
  ];

  return (
    <div className={`section section-padding-t90-b100 ${classOption}`}>
      <div className="container">
        <SectionTitle
          title="Why Choose Us"
          subTitle="Time Study is your best choice of education and migration agency because:"
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
          {IconBoxData &&
            IconBoxData.map((single, key) => {
              return (
                <div key={key} className="col mb-6" data-aos="fade-up">
                  <IconBox classOption="box-border" data={single} key={key} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ServiceIconBox.propTypes = {
  classOption: PropTypes.string,
};
ServiceIconBox.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default ServiceIconBox;
