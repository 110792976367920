import PropTypes from "prop-types";
import React from "react";

const BlogItem = ({ data }) => {
  return (
    <div className="blog">
      <div className="thumbnail">
        <img
          src={process.env.PUBLIC_URL + data.image}
          style={{ height: "450px", width: "100%" }}
          alt="Blog Image"
        />
      </div>
      <div className="info">
        <ul className="meta">
          <li>
            <i className="far fa-user"></i>
            {data.designation}
          </li>
        </ul>
        <h3 className="title">{data.title}</h3>
        <p>{data.desc}</p>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  data: PropTypes.object,
};

export default BlogItem;
