import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import CallToAction from "../../container/CallToAction/CallToAction";
import Accordion from "../../components/Accordion/AccordionWrap";

const HomeAboutThree = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo
                  subTitle="Choosing the right Institution"
                  title="TAFE & Private College"
                />
                <p>
                  Vocational education is a form of tertiary education that
                  intends to provide the skills and experience needed for a
                  person to enter a specific industry. It is often referred to
                  as Vocational Education and Training (VET) and Career and
                  Technical Education (CTE). In Australia, vocational education
                  and training are mostly post-secondary. However, some senior
                  schools do offer school-based apprenticeships and traineeships
                  for students in years 10, 11, and 12.
                </p>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <Tilt scale={scale} transitionSpeed={4000}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "images/current/intern/2.jpg"
                      }
                      alt=""
                    />
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene" ref={sceneEl}>
                  <span data-depth="1">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "images/shape-animation/video-shape-1.png"
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                Australia’s VET sector is based on a partnership between
                governments and industry. VET qualifications are provided by
                government institutions, called Technical and Further Education
                (TAFE) institutions and some private institutions. Vocational
                education covers a large number of careers and industries such
                as office work, retail, hospitality and technology.
              </p>
              <h3>Benefits of enrolling in a VET Course</h3>
              <ul>
                <li>
                  The VET course provides flexible and weekly attendance, making
                  it ideal for students who want to work while they are
                  studying.
                </li>
                <li>
                  The fee for VET courses can be paid via instalments and on a
                  long term basis.
                </li>
                <li>
                  In terms of fee, vocational courses are conveniently priced
                  compared to higher education like university courses
                </li>
                <li>
                  VET courses are more career-oriented and can partially
                  substitute a higher education pathway.
                </li>
                <li>
                  Institutes that provide VET courses mostly offer direct
                  placement to the working industry
                </li>
                <li>
                  A qualifying training course is sometimes mandatory and turns
                  out to be an advantage to undertake specific jobs.
                </li>
              </ul>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <p>
                  According to The Australian Qualifications Framework (AQF),
                  VET qualifications range across four levels of certificates
                  (Certificate I, II, III and IV), Diploma, and Advanced
                  Diploma. The duration and possible career outcome of these
                  qualifications are:
                </p>
                <h3>Which institution provides VET?</h3>
                <p>VET providers can include:</p>
                <ul>
                  <li>technical and further education (TAFE) institutes</li>
                  <li>adult and community education providers</li>
                  <li>agricultural colleges</li>
                  <li>private providers</li>
                  <li>community organisations</li>
                  <li>industry skill centres</li>
                  <li>commercial and enterprise training providers.</li>
                </ul>
                <p>Some universities and schools also provide VET.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <div className="container text-left">
          <h3>Qualifications</h3>
          <p>
            According to The Australian Qualifications Framework (AQF), VET
            qualifications range across four levels of certificates (Certificate
            I, II, III and IV), Diploma, and Advanced Diploma. The duration and
            possible career outcome of these qualifications are:
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Degree</th>
                <th scope="col">Duration</th>
                <th scope="col">Career Outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Certificate I</td>
                <td>4 - 6 months</td>
                <td>competent operator</td>
              </tr>{" "}
              <tr>
                <td>Certificate II</td>
                <td>about 1 year</td>
                <td>advanced operator</td>
              </tr>
              <tr>
                <td>Certificate III</td>
                <td>about 1 year</td>
                <td>qualified tradesperson or technician</td>
              </tr>
              <tr>
                <td>Certificate IV</td>
                <td>12 - 18 months</td>
                <td>supervisor</td>
              </tr>
              <tr>
                <td>Diploma</td>
                <td>18 - 24 months</td>
                <td>para-professional</td>
              </tr>
              <tr>
                <td>Advanced Diploma</td>
                <td>24 - 36 months</td>
                <td>junior manager</td>
              </tr>
            </tbody>
          </table>
          <p>
            source:{" "}
            <a href="https://www.studyinaustralia.gov.au/english/australian-education/vocational-education">
              Visit Source
            </a>
          </p>
        </div>
      </div>
      <div className="faq-section section section-padding-top bg-primary-blue">
        <div className="container">
          <div className="row row-cols-lg-2 row-cols-1">
            <div className="col mb-6" data-aos="fade-up">
              <div className="faq-content">
                <SectionTitleTwo
                  subTitle="CHOOSING THE RIGHT INSTITUTION"
                  title="What does VET Involve?"
                />

                <p>
                  Unlike higher education, VET courses emphasise more on
                  occupational skills rather than theory. It covers the
                  following areas:
                </p>
                <ul>
                  <li>Australian traineeships and apprenticeships</li>
                  <li>
                    Basic such as literacy and numeracy training which involves
                    foundation studies and pre-vocational training.
                  </li>
                  <li>
                    Vocational skills for specific areas such as automotive or
                    floristry.
                  </li>
                  <li>
                    Semi-professional vocational training in fields like
                    occupational safety and health or business advertising.
                  </li>
                </ul>
                <p>
                  The VET courses require students to complete their study and
                  assessments at their training institutions to demonstrate that
                  they have obtained the required skill levels.
                </p>
              </div>
            </div>

            <div
              className="col pl-xl-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h4>
                VET qualifications are offered across a broad range of subjects.
                Some of the most popular courses include:
              </h4>
              <div className="row">
                <div className="col mb-6">
                  <ul>
                    <li>Training and Assessment</li>
                    <li>Aged Care</li>
                    <li>Education Support</li>
                    <li>Accounting</li>
                    <li>Management</li>
                    <li>Business</li>
                    <li>Business Administration</li>
                    <li>Small Business</li>
                    <li>Community Services</li>
                    <li>Cyber Security</li>
                  </ul>
                </div>{" "}
                <div className="col mb-6">
                  <ul>
                    <li>Financial Planning</li>
                    <li>Counselling</li>
                    <li>Project Management</li>
                    <li>Veterinary Nursing</li>
                    <li>Early Childhood Education & Care</li>
                    <li>Beauty Therapy</li>
                    <li>Nursing</li>
                    <li>Electrotechnology</li>
                    <li>Building Design</li>
                    <li>Horticulture</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <div className="mb-5 section-padding-top">
        <div className="container text-center">
          <h3>Vocational Education and Training</h3>

          <Accordion />
        </div>
      </div>
    </>
  );
};

export default HomeAboutThree;
