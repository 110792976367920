import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Service from "./pages/Service";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import BlogGrid from "./pages/BlogGrid";
import BlogClassic from "./pages/BlogClassic";
import BlogDetails from "./pages/BlogDetails";
import BlogCategories from "./pages/BlogCategories";
import BlogTag from "./pages/BlogTag";
import Contact from "./pages/Contact";
import AustraliaEducation from "./pages/AustraliaEducation";
import EnglishCourses from "./pages/EnglishCourse";
import Schooling from "./pages/Schooling";
import StudyTour from "./pages/StudyTour";
import VocationalEducation from "./pages/VocationalStudy";
import University from "./pages/University";
import ProfessionalYear from "./pages/ProfessionalYear";
import Admission from "./pages/Admission";
import BusinessInvestment from "./pages/BusinessInvestment";
import EmployerSponsored from "./pages/EmployerSponsored";
import SkilledMigration from "./pages/SkilledMigration";
import FamilyPartner from "./pages/FamilyPartner";
import VisitorVisa from "./pages/VisitorVisa";
import StudentVisa from "./pages/StudentVisa";
import TempGradVisa from "./pages/TempGradVisa";
import AAT from "./pages/AAT";
import "react-loading-skeleton/dist/skeleton.css";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-one"}`}
            exact
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-two"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-three"}`}
            component={HomeThree}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={Service}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/work"}`}
            component={Work}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/work-details/:id"}`}
            component={WorkDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
            component={BlogGrid}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={BlogClassic}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
            component={BlogTag}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
            component={BlogCategories}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/australia-education"}`}
            component={AustraliaEducation}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/english-course"}`}
            component={EnglishCourses}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/schooling"}`}
            component={Schooling}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/study-tour"}`}
            component={StudyTour}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/vocational-education"}`}
            component={VocationalEducation}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/university"}`}
            component={University}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/professional-year"}`}
            component={ProfessionalYear}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/admission"}`}
            component={Admission}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/business-investment"}`}
            component={BusinessInvestment}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/employer-sponsored"}`}
            component={EmployerSponsored}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/skilled-migration"}`}
            component={SkilledMigration}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/family-partner"}`}
            component={FamilyPartner}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/visitor-visa"}`}
            component={VisitorVisa}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/student-visa"}`}
            component={StudentVisa}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/temporary-graduate-visa"}`}
            component={TempGradVisa}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/visa-refusal"}`}
            component={AAT}
          />
          {/* <Route component ={NotFound} /> */}
        </Switch>
      </NavScrollTop>
    </Router>
  );
}

export default App;
