import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Accordion from "../../components/Accordion/AccordionWrap1";

const HomeAboutThree = () => {
  return (
    <>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Associate Degree" />
                <ul>
                  <li>Duration – Two years</li>
                  <li>
                    Completed following – Year 12 or equivalent, Certificate III
                    or Certificate IV
                  </li>
                  <li>
                    Details – The Associate Degree is a short-cycle Degree
                    program that can help with career development if you’re
                    already working and don’t have time to complete a full
                    Bachelor Degree. It can be a pathway to a Bachelor Degree or
                    prepare you for an Advanced Diploma in specialist industry
                    training. An Associate Degree will be about the basics or
                    foundations of your field. It will cover the broad theory
                    and enable you to develop the basic employment-related
                    skills.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={process.env.PUBLIC_URL + "images/current/intern/2.jpg"}
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/12.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Bachelor Degree" />
                <ul>
                  <li>Duration – Three years</li>
                  <li>
                    Completed following – Year 12 or equivalent, Certificate III
                    or Certificate IV
                  </li>
                  <li>
                    Details – A Bachelor Degree is the basic qualification for
                    entry to a field. It will give you a systematic body of
                    knowledge, the underlying principles and the problem-solving
                    techniques you’ll need to work in the discipline. With
                    deeper content than an Associate Degree, your Bachelor
                    Degree will develop your skills and knowledge to the point
                    where you can pursue postgraduate studies if you want to
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Graduate Certificate" />
                <ul>
                  <li>Duration – 6 months</li>
                  <li>Completed following – Bachelor Degree</li>
                  <li>
                    Details – Your Graduate Certificate will broaden individual
                    skills you already have from an undergraduate program, or
                    develop your knowledge in a new area.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/13.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/14.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Graduate Diploma" />
                <ul>
                  <li>Duration – One year</li>
                  <li>Completed following – Bachelor Degree</li>
                  <li>
                    Details – A Graduate Diploma will apply a body of knowledge
                    in a range of contexts for you to undertake professional or
                    highly skilled work and as a pathway for further learning.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Master Degree" />
                <ul>
                  <li>Duration – 1 to 2 years</li>
                  <li>
                    Completed following – Bachelor Degree (Honours) or Bachelor
                    Degree
                  </li>
                  <li>
                    Details – A Master Degree is a course of independent
                    research, traditional coursework or a combination of the
                    two, and will make you a leader in your field. A Master
                    Degree takes either one year after a Bachelor Degree with
                    honours, or two years after a Bachelor Degree.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/15.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-padding-top section-padding-bottom-150 bg-primary-blue">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-image-area about-shape-animation right-0 mr-0">
                <div className="about-image js-tilt">
                  <img
                    src={
                      process.env.PUBLIC_URL + "images/current/services/16.jpg"
                    }
                    alt="xsxs"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
              <div className="">
                <SectionTitleTwo title="Doctoral Degree" />
                <ul>
                  <li>Duration – Typically 3 years</li>
                  <li>Completed following – Masters Degree</li>
                  <li>
                    Details – The highest award offered by Australian
                    universities, the Doctoral Degree is a research program,
                    although it can have some coursework.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 section-padding-top">
        <div className="container text-center">
          <h3>
            Details – The highest award offered by Australian universities, the
            Doctoral Degree is a research program, although it can have some
            coursework.
          </h3>
          <p>
            If you are unable to meet the entry requirements to get into your
            desired course in Australia, there are other pathways that can help
            to reach your goal. This might include studying in an Australian
            school, taking English language preparation or studying a vocational
            education, and attending training courses.
          </p>
          <p>
            One of the most popular pathways is Foundation Studies – one-year
            intensive preparatory courses available through the majority of
            institutions. These will give you the skills you need to enter an
            undergraduate (Bachelor Degree) course.
          </p>
          <Accordion />
        </div>
      </div>
    </>
  );
};

export default HomeAboutThree;
